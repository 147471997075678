// import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSessions,
  fetchOne,
  selectSessionById,
  selectSessionsError,
  selectSessionsStatus,
  updateSession,
} from "./sessionSlice";
import { useEffect, useState } from "react";
import {
  selectClassesStatus,
  selectAllClasses,
} from "../classes/slice";
import { fetchClasses } from "../classes/slice";
import Alert from "../../components/common/alert";

export const EditModal = ({ show, handleClose, sessionId }) => {
  console.log("sessionId", sessionId)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [updates, setUpdates] = useState({});

  const session = useSelector((state) => selectSessionById(state, sessionId));
  // const { id } = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const classes = useSelector(selectAllClasses);
  const classStatus = useSelector(selectClassesStatus);

  const sessionsStatus = useSelector(selectSessionsStatus);
  const sessionError = useSelector(selectSessionsError);

  // const handleClassInputChange = (e) => {
  //   // setClassId(e.target.value);
  //   if (classes.length !== 0) {
  //     const classItem = classes.find(
  //       (item) => item.id === parseInt(e.target.value)
  //     );
  //     setUpdates({ ...updates, class_id: classItem.id });
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name/val", name, value);
    setUpdates({ ...updates, [name]: value, id: sessionId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("updates", updates)
    try {
      dispatch(updateSession(updates));
    } catch (error) {
      const { data: response } = error;
      setAlertMsg({
        ...alertMsg,
        msg: response.msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Session updated successfully",
      });
      setShowAlert(true);
      //  e.target.reset();
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    console.log("SessionID", sessionId)
    dispatch(fetchOne({ sessionId: sessionId }));
    if (classes.length === 0 && classStatus === "idle") {
      dispatch(fetchClasses());
      // console.log("CourseID", courseItem);
    }
  }, [classes.length, classStatus, dispatch, showAlert, sessionId]);
  return (
    <>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:w-1/2 sm:w-3/4">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto text-center">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Edit session details
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr className="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  {/* <AddSessionForm session={session} /> */}
                  <div className="flex justify-center col-span-full">
                    {showAlert && (
                      <Alert
                        show={showAlert}
                        content={sessionError}
                        type={sessionsStatus}
                      ></Alert>
                    )}
                  </div>
                  {!session ? (
                    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-700 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mx-auto max-w-6xl">
                      <form className="mt-6" onSubmit={handleSubmit}>
                        <div className="flex flex-col sm:flex-row justify-start items-center">
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <h1 className="text-base mx-3 font-medium text-gray-900">
                              Course:
                            </h1>
                            <h1 className="text-base font-base text-gray-900">
                              {session.course}
                            </h1>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <h1 className="text-base mx-3 font-medium text-gray-900">
                              Class:
                            </h1>
                            <h1 className="text-base font-base text-gray-900">
                              {session.class}
                            </h1>
                          </div>
                        </div>

                        <div className="flex flex-col sm:flex-row justify-start items-center mt-4 border py-4 border-gray-300 rounded-md shadow-sm">
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <h1 className="text-base mx-3 font-medium text-gray-900">
                              From:
                            </h1>
                            <h1 className="text-base font-base text-gray-900">
                              {session.start_at}
                            </h1>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <h1 className="text-base mx-3 font-medium text-gray-900">
                              To:
                            </h1>
                            <h1 className="text-base font-base text-gray-900">
                              {session.end_at}
                            </h1>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-between items-center">
                            <h1 className="text-base mx-3 font-medium text-gray-900">
                              Day
                            </h1>
                            <h1 className="text-base font-base text-gray-900">
                              {session.day}
                            </h1>
                          </div>
                        </div>
                        <div className="col-span-full">
                          <div className="p-4 mt-3 bg-white border border-gray-200 rounded-xl shadow-sm">
                            <fieldset>
                              <div className="mt-3 space-y-2">
                                <div className="flex items-center gap-x-3">
                                  <label
                                    htmlFor="session-class"
                                    className="block text-sm font-medium leading-6 text-gray-700"
                                  >
                                    Class
                                  </label>
                                  <select
                                    id="session-class"
                                    name="class_id"
                                    placeholder="Select Class"
                                    defaultValue={session.class_id}
                                    onChange={handleInputChange}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  >
                                    {/* <option value="">
                                      Select Class
                                    </option> */}
                                    {classes
                                      ?.filter(
                                        (item) =>
                                          item.course_id === session.course_id
                                      )
                                      .map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.class_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="flex items-center gap-x-3">
                                  <label
                                    htmlFor="session-day"
                                    className="block text-sm font-medium leading-6 text-gray-700"
                                  >
                                    Day
                                  </label>
                                  <select
                                    id="session-day"
                                    name="day"
                                    placeholder="Select Class"
                                    // value={updates.day || ""}
                                    defaultValue={session?.day}
                                    onChange={handleInputChange}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  >
                                    {/* <option>Select Day</option> */}
                                    <option key="sat" value="Saturday">
                                      Saturday
                                    </option>
                                    <option key="sun" value="Sunday">
                                      Sunday
                                    </option>
                                    <option key="chapel" value="Chapel">
                                      Sunday Chapel
                                    </option>
                                  </select>
                                </div>
                                <div className="flex items-end gap-x-3">
                                  <div className="flex items-center gap-x-3">
                                    <label
                                      htmlFor="session-start"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Start At:
                                    </label>
                                    <input
                                      id="session-start"
                                      name="start_at"
                                      type="time"
                                      min="07:00"
                                      max="18:00"
                                      defaultValue={session?.start_at}
                                      placeholder="Start Time"
                                      // value={updates.start_at || ""}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    ></input>
                                  </div>
                                  <div className="flex items-center gap-x-3">
                                    <label
                                      htmlFor="session-end"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      End At:
                                    </label>
                                    <input
                                      id="session-end"
                                      name="end_at"
                                      type="time"
                                      min="07:00"
                                      max="18:00"
                                      defaultValue={session?.end_at}
                                      // value={updates.end_at || ""}
                                      placeholder="Session End Time"
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <button
                            type="submit"
                            className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
