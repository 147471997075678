import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectGetResults } from "../../features/registrations/registrationSlice";
import { Link } from "react-router-dom";
import {
  enrollStudent,
  selectEnrollment,
  selectEnrollResp,
} from "../../features/enrollment/enrollSlice";

export const Payment = () => {
  const studentObject = useSelector(selectGetResults);
  const enrollData = useSelector(selectEnrollment);
  const enrollResponse = useSelector(selectEnrollResp);

  const dispatch = useDispatch();

  //   const handlePhoneInput = (e) => {
  //       setPayingMSISDN(e.target.value);
  //       console.log("", payingMSISDN)
  //     }
  const handleEnrollment = async (data) => {
    data = {
      ...data,
      payment_method: "express",
      paying_msisdn: studentObject.msisdn,
    };
    console.log("enrollData", data);
    await dispatch(enrollStudent(data));
    console.log("enrollResponse", enrollResponse);
  };

  // const enrollData = {
  //   course_id: course_id,
  //   student_id: studentObject.id,
  //   payment_method: "express",
  //   paying_msisdn: payingMSISDN,
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleEnrollment(enrollData).then(() => {
      console.log("success");
    });
  };
  useEffect(() => {}, [dispatch, enrollData, enrollResponse]);

  return (
    <>
      <div className="px-4 py-6 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/freewa-8.png"
              alt=""
              className="h-40 w-auto"
            />
          </a>
        </div>
      </div>

      <h1 className="sr-only">IYF Free Academy - Registration</h1>

      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="hidden pb-4 pt-12 lg:flex">
            <a href="/">
              <span className="sr-only">IYF Free Academy</span>
              <img
                src="../assets/png/freewa-8.png"
                alt=""
                className="h-40 w-auto"
              />
            </a>
          </div>

          <form className="mt-6" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <h1 className="text-2xl font-bold">
                    {studentObject.full_name}, Pay Kshs 500
                  </h1>
                  <p>Pay Kshs 500 to Enroll to UI UX Class</p>
                </div>
              </div>

              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <label className="block text font-bold text-gray-700">
                    Mpesa Express
                  </label>
                  <p className="block text-sm font-medium text-gray-700 mb-2">
                    Send an M-Pesa payment request to your phone number
                  </p>
                  <input
                    type="hidden"
                    id="payment_method"
                    name="payment_method"
                    onChange={() => {}}
                    autoComplete="Phone"
                    value="express"
                    placeholder="eg. 0700 123 456"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  />

                  <div className="mt-1 flex flex-col space-y-2 sm:flex-row sm:justify-between sm:items-center sm:space-y-0 sm:space-x-2">
                    <input
                      type="text"
                      id="phone-number"
                      name="paying_msisdn"
                      autoComplete="Phone"
                      value={studentObject.msisdn}
                      placeholder="eg. 0700 123 456"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                    <button
                      href="/"
                      type="submit"
                      className="w-full sm:w-auto rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-nowrap"
                    >
                      Send Payment Request
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between">
              <Link
                to="/"
                className="mt-6 rounded-md text-sm text-gray-500 px-4 py-2 hover:bg-white"
              >
                Cancel
              </Link>
              {/* <a
                  href="mpesa-classic.html"
                  className="mt-6 rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Use Mpesa Classic
                </a> */}
            </div>

            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
              <svg
                className="mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                  clipRule="evenodd"
                />
              </svg>
              We adhere to the highest standards of data security in all
              processing and storage of your information.
            </p>
          </form>
        </div>
      </section>
    </>
  );
};
