import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";
// import { saveItem } from "../../utils/helpers/localStorage";
// import { useSelector } from "react-redux";

// export const fetchRegistrations = createAsyncThunk(
//   "/registrations/fetchRegistrations",
//   async () => {
//     const { data: response } = await Apis.getRegistration();
//     console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const getStudent = createAsyncThunk(
  "/registrations/getStudent",
  async (param, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.fetchStudent(param);
      if (!success) {
        return { data: null, msg: msg, status: 400 };
      }
      return { data: data, msg: msg, status: 200 };
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      // console.log("SessionError", msg, status);
      return rejectWithValue(
        msg || "Could not fetch student info at this moment. Try again later"
      );
    }

  //   const { data: response } = await Apis.fetchStudent(param);
  //   // console.log("Reg Found >>>", response.data);
  //   if (response.data) {
  //     return response.data;
  //   } else {
  //     return null;
  //   }
  
  }
);

export const fetchRegistrations = createAsyncThunk(
  "/registrations/fetchAll",
  async ({ page, pageSize, search }, { getState }) => {
    try {
      // check stored cache
      const { registration } = getState();
      // console.log("state", registration);
      const cachedPage = registration.pages[`${page}-${search}`];
      if (cachedPage){
        // console.log("In cache", cachedPage)
        return {
          students: cachedPage,
          total: registration.totalShowing,
          page,
          search,
        };
      }

      // console.log("in fetch")
      const {
        data: { students, total}
      } = await Apis.getRegistrations({
        params: { page, pageSize, search },
      });
  
      // console.log("res", students);
      return { students, total, page, search };
    } catch (error) {
      console.log(error)
      return error.response.data;
      // return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCount = createAsyncThunk(
  "/registrations/fetchCount",
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { count },
      } = await Apis.getRegistrationCount();
      return count;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerStudent = createAsyncThunk(
  "students/registerStudent",
  async (student) => {
    try {
      const { data: response, status } = await Apis.registerStudent(student);
      if (!response.success) {
        return { ...response, status: status };
      }
      return { ...response, status: 201 };
    } catch (error) {
      console.log("RegisterStudent Error", error);
      const {
        response: { data },
        status,
      } = error;
      return {  ...data, status: status };
    }
  }
);

export const updateRegistration = createAsyncThunk(
  "students/updateRegistration",
  async (student) => {
    try {
      const { data: response } = await Apis.updateStudent(student);
      if (!response.success) {
        return { ...response, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      return { ...response, status: 500 };
    }
  }
);

const initialState = {
  students: [],
  status: "idle",
  msg: null,
  getResults: {},
  student: {},
  currentPage: 1,
  pageSize: 100,
  totalCount: 0,
  todayCount: 0,
  studentsPerCourse: [],
  totalShowing: 0,
  pages: {}, //cached pages
  search: ""
};

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    // registerStudent: (state, action) => {
    //   const registrations = state.students;
    //   const newRegistrations = [...registrations, action.payload];

    //   return {
    //     ...state.students,
    //     newRegistrations,
    //   };
    // },
    getStudentItem: (state) => state.student,
    getStatus: (state) => state.status,
    setSearchResult: (state, action) => {
      return { ...state, getResults: action.payload };
    },
    setNewStudent: (state, action) => {
      return { ...state, newStudent: action.payload };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    selectRegistrationState: (state) => state.status,
    selectStudent: (state) => state.student,
    selectGetResults: (state) => state.getResults,
    // selectRegistrations: (state, page) => state.students[page] || [],
    selectRegistrations: (state) => state.students,
    selectStudentById: (state, id) =>
      state.students.find((student) => student.id === id),
    selectErrorMsg: (state) => state.msg,
    selectCurrentPage: (state) => state.currentPage,
    selectPageSize: (state) => state.pageSize,
    selectTotalCount: (state) => state.totalCount,
    selectStudentsPerCourse: (state) => state.studentsPerCourse,
    selectTotalShowing: (state) => state.totalShowing,
    selectTodayCount: (state) => state.todayCount,
    selectSearchTerm: (state) => state.search,
  },
  extraReducers(builder) {
    builder
      .addCase(registerStudent.fulfilled, (state, action) => {
        state.status = "success";
        const { data, msg, status } = action.payload;
        if (status !== 200) {
          state.status = "success";
          state.msg = msg;
        } else {
          state.student = data;
          state.students.push(data);
        }
      })
      .addCase(registerStudent.pending, (state, action) => {
        state.status = "pending";
        // state.registration.push(action.payload);
      })
      .addCase(registerStudent.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload.msg;
      })
      .addCase(updateRegistration.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        if (status !== 200) {
          state.status = "failed";
          state.msg = msg;
        } else {
          // state.student = data;
          state.status = "success";
          state.students = state.students.map((student) => {
            if (student.id === data.id) {
              const updated = { ...student, ...data };
              return updated;
            } else {
              return student;
            }
          });
        }
      })
      .addCase(updateRegistration.pending, (state, action) => {
        state.status = "pending";
        // state.registration.push(action.payload);
      })
      .addCase(updateRegistration.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.payload.msg;
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.status = "success";
        const { data, msg } = action.payload;
        state.student = data;
        state.msg = msg
      })
      .addCase(getStudent.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getStudent.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchRegistrations.fulfilled, (state, action) => {
        const { students, total, page, search } = action.payload;
        state.status = "success";
        // if (search) {
        //   console.log("yes ", data.students.length)
        //   state.totalCount = data.students.length;
        // }
        // if (Array.isArray(data)) {
          state.totalShowing = total;
          // state.students[page] = data;
          state.students = students;
          state.pages[`${page}-${search}`] = students; // Store students search results
        // } else {
        //   state.student = data;
        // }
      })
      .addCase(fetchRegistrations.pending, (state, action) => {
        state.status = "pending";
        state.msg = null;
      })
      .addCase(fetchRegistrations.rejected, (state, action) => {
        state.status = "failed";
        state.msg = action.error.message;
      })
      // Fetch total count of students
      .addCase(fetchCount.fulfilled, (state, action) => {
        const { total, today, students_per_course } = action.payload;
        state.totalCount = total;
        state.todayCount = today;
        state.studentsPerCourse = students_per_course || [];
      })
      .addCase(fetchCount.rejected, (state, action) => {
        state.msg = action.payload;
      });
  },
});
export const {
  setSearchResult,
  setNewStudent,
  getNewStudent,
  getStatus,
  getStudentItem,
  setCurrentPage,
  setPageSize,
  setSearchTerm,
} = registrationSlice.actions;

export const {
  selectStudent,
  selectRegistrationState,
  selectGetResults,
  selectRegistrations,
  selectErrorMsg,
  selectStudentById,
  selectCurrentPage,
  selectPageSize,
  selectTotalCount,
  selectTodayCount,
  selectTotalShowing,
  selectSearchTerm,
  selectStudentsPerCourse,
} = registrationSlice.selectors;

// const selectRegistrations = useSelector
export default registrationSlice.reducer;
