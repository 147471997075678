import React, { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Apis from "../../utils/api/api";

const ExportRegistrationsCSV = ({ show, handleClose }) => {
  const [createdFrom, setCreatedFrom] = useState();
  const [createdTo, setCreatedTo] = useState();
  const [exporting, setExporting] = useState(false);

  // const dispatch = useDispatch();

  // const data = useSelector(selectEnrollments);

  const handleExportCSV = async () => {
    setExporting(true);
    try {
      const response = await Apis.exportRegistrations({
        params: { createdFrom, createdTo },
        responseType: "blob", // We expect binary data (CSV file) as a response
      });

      // Create a URL for the blob object and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `Registrations_${Math.floor(Date.now() / 1000)}.csv`;
      link.setAttribute("download", fileName); // CSV filename
      document.body.appendChild(link);
      link.click();
      link.remove();
      setCreatedFrom("");
      setCreatedTo("");
    } catch (error) {
      setExporting(false);
      console.error("Error exporting data:", error);
      alert("An error occurred while exporting data.");
    } finally {
      setExporting(false);
    }
  };

  // const handleExportCSV = async () => {
  //   // Map the person data to CSV format
  //   const csvData = data.map((student) => ({
  //     "Full Name": student.full_name,
  //     "Phone Number": student.msisdn,
  //     "Registration Number": student.registration_no,
  //     Course: student.class.course,
  //     Class: student.class.class,
  //     "Enrollment Type": student.enrollment_type,
  //     "Enrollment Status": student.status,
  //     Residence: student.residence,
  //     "Registration Date": student.created_at,
  //   }));

  //   // Use PapaParse to convert JSON data to CSV
  //   const csv = Papa.unparse(csvData);

  //   const fileName = `registrations_${Math.floor(Date.now() / 1000)}`;

  //   // Create a Blob with the CSV content and specify the file type
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  //   // Use FileSaver to save the file to the user's Downloads folder
  //   saveAs(blob, `${fileName}.csv`);
  //   setCreatedFrom("");
  //   setCreatedTo("");
  // };
  // useEffect(() => {
  //   dispatch(
  //     fetchEnrollments({
  //       createdFrom: createdFrom,
  //       createdTo: createdTo,
  //     })
  //   );
  // }, [createdFrom, createdTo, dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {show && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
              <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-md sm:max-w-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h1 className="text-lg font-semibold text-gray-900">
                      Select Dates to Export
                    </h1>
                    <button
                      type="button"
                      className="p-2 rounded-full text-red-500 hover:bg-red-100"
                      onClick={handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <hr className="my-3 border-gray-200" />

                  <div className="grid gap-4 sm:grid-cols-2">
                    <div>
                      <label
                        htmlFor="createdFrom"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date From:
                      </label>
                      <input
                        type="date"
                        id="createdFrom"
                        name="createdFrom"
                        required
                        defaultValue={createdFrom}
                        min="2000-01-01"
                        max="2070-12-31"
                        onChange={(e) => setCreatedFrom(e.target.value)}
                        className="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="createdTo"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date To:
                      </label>
                      <input
                        type="date"
                        id="createdTo"
                        name="createdTo"
                        required
                        defaultValue={createdTo}
                        min="2000-01-01"
                        max="2070-12-31"
                        onChange={(e) => setCreatedTo(e.target.value)}
                        className="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    {exporting ? (
                      <button
                        type="button"
                        className="w-full rounded-md bg-green-300 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm"
                        disabled
                      >
                        Exporting...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={!createdFrom || !createdTo}
                        className="w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-400"
                        onClick={handleExportCSV}
                      >
                        Export
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LocalizationProvider>
  );
};

export default ExportRegistrationsCSV;
