import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  fetchCount as getEnrollmentCount,
  selectTotalCount as totalEnrollmentCount,
  selectTotalActive as totalActiveEnrollments,
  selectTotalPending as totalPendingEnrollments,
  selectTodayCount as enrollmentsToday,
  selectCountPerCourse as enrollmentPerCourse
} from "../../features/enrollment/enrollSlice";
import {
  fetchCount as fetchRegistrationCount,
  selectTotalCount as registrationsCount,
  selectTodayCount as registrationsToday,
  selectStudentsPerCourse as registrationPerCourse,
} from "../../features/registrations/registrationSlice";

const RegistrationsCard = ({ registrationsPerCourse }) => {
  // Split courses into chunks of 5
  const chunkSize = 5;
  const chunkedCourses = [];
  for (let i = 0; i < registrationsPerCourse.length; i += chunkSize) {
    chunkedCourses.push(registrationsPerCourse.slice(i, i + chunkSize));
  }

  return (
    <div className="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <h5 className="flex justify-center py-5 leading-none text-base font-bold text-gray-700 dark:text-white">
        Registrations Per Course
      </h5>
      <hr></hr>
      <div
        className={`grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-${chunkedCourses.length}`}
        // style={{
        //   gridTemplateColumns: `repeat(${chunkedCourses.length}, minmax(150px, 1fr))`,
        // }}
      >
        {chunkedCourses.map((group, colIdx) => (
          <div
            key={colIdx}
            className="space-y-2 md:border-l border-gray-300 dark:border-gray-700 md:pl-4"
            // className={`space-y-2 ${
            //   colIdx !== 0
            //     ? "md:border-l border-gray-300 dark:border-gray-700 md:pl-4"
            //     : ""
            // }`}
          >
            {group.map((course, idx) => (
              <div
                key={idx}
                className="flex items-center space-x-3 px-2.5 py-0.5 border-b border-gray-300 dark:border-gray-700"
              >
                <h5 className="text-lg font-normal text-gray-700 dark:text-gray-400">
                  {course.name}: <b>{course.count}</b>
                </h5>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const EnrollmentsCard = ({ enrollmentsPerCourse }) => {
  // Split courses into chunks of 5
  const chunkSize = 5;
  const chunkedCourses = [];
  for (let i = 0; i < enrollmentsPerCourse.length; i += chunkSize) {
    chunkedCourses.push(enrollmentsPerCourse.slice(i, i + chunkSize));
  }

  return (
    <div className="w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
      <h5 className="flex justify-center py-5 leading-none text-base font-bold text-gray-700 dark:text-white">
        Enrollment Per Course
      </h5>
      <hr></hr>
      <div
        className={`grid gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-${chunkedCourses.length}`}
        // style={{
        //   gridTemplateColumns: `repeat(${chunkedCourses.length}, minmax(150px, 1fr))`,
        // }}
      >
        {chunkedCourses.map((group, colIdx) => (
          <div
            key={colIdx}
            className="space-y-2 md:border-l border-gray-300 dark:border-gray-700 md:pl-4"
            // className={`space-y-2 ${
            //   colIdx !== 0
            //     ? "md:border-l border-gray-300 dark:border-gray-700 md:pl-4"
            //     : ""
            // }`}
          >
            {group.map((course, idx) => (
              <div
                key={idx}
                className="flex items-center space-x-3 px-2.5 py-0.5 border-b border-gray-300 dark:border-gray-700"
              >
                <h5 className="text-lg font-normal text-gray-700 dark:text-gray-400">
                  {course.name}: <b>{course.count}</b>
                </h5>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Stats = () => {
  // const paymentRequests = useSelector(selectPaymentRequests);
  // const stkRequests = useSelector(selectSTKRequests);
  const totalEnrollments = useSelector(totalEnrollmentCount);
  const pendingEnrollmentsCount = useSelector(totalPendingEnrollments);
  const activeEnrollmentCount = useSelector(totalActiveEnrollments);
  const registrationCount = useSelector(registrationsCount);
  const regTodayCount = useSelector(registrationsToday);
  const enrollTodayCount = useSelector(enrollmentsToday);
  const registrationsPerCourse = useSelector(registrationPerCourse);
  const enrollmentsPerCourse = useSelector(enrollmentPerCourse);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegistrationCount());
    dispatch(getEnrollmentCount());
  }, [dispatch]);
  return (
    <>
      <div className="px-8 sm:flex sm:items-center sm:justify-between space-y-2">
        <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
          {!registrationCount ? (
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-300 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-300 rounded"></div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <div>
                  <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                    {registrationCount}
                  </h5>
                  <p className="text-base font-medium text-gray-500 dark:text-gray-400">
                    Total Registrations
                  </p>
                </div>
                <div className="grid grid-cols-1 items-center border-gray-200 dark:border-gray-700 justify-between">
                  <h3 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                    {/* {enrollments?.reduce((item, { status }) => {
                      if (status === "pending") {
                        item += 1;
                      }
                      return item;
                    }, 0)}{" "} */}
                    {regTodayCount}
                    <p className="text-base font-medium text-gray-700 dark:text-gray-400">
                      Today
                    </p>
                  </h3>
                  {/* <div className="flex items-center space-x-3 px-2.5 py-0.5 text-medium font-semibold text-green-500 dark:text-green-500 text-center">
                      
                    </div> */}
                </div>
                {/* <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
                12%
                <svg
                  className="w-3 h-3 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13V1m0 0L1 5m4-4 4 4"
                  />
                </svg>
              </div> */}
              </div>
              <div id="area-chart"></div>
              <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
                <div className="flex justify-between items-center pt-5">
                  <Link
                    to="/dashboard/registrations"
                    className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
                  >
                    See all
                    <svg
                      className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
          {/* {!totalEnrollments ? ( */}
          {!activeEnrollmentCount === undefined ? (
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-300 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-300 rounded"></div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <div>
                  <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                    {/* {enrollments?.reduce((item, { status }) => {
                    if (status === "active") {
                      item += 1;
                    }
                    return item;
                  }, 0)} */}
                    {activeEnrollmentCount}
                  </h5>
                  <p className="text-base font-medium text-gray-500 dark:text-gray-400">
                    Total Enrolled
                  </p>
                </div>
                <div className="grid grid-cols-1 items-center border-gray-200 dark:border-gray-700 justify-between">
                  {/* <img
                  src="../assets/svg/clock-svgrepo-com.svg"
                  alt=""
                  className="h-5 w-auto bg-orange-400"
                />
                {" "} */}
                  <div className="flex items-center space-x-3 px-2.5 py-0.5">
                    <h3 className="text-medium font-semibold text-center text-amber-600 dark:text-amber-600">
                      {/* {enrollments?.reduce((item, { status }) => {
                      if (status === "pending") {
                        item += 1;
                      }
                      return item;
                    }, 0)}{" "} */}
                      {enrollTodayCount}
                    </h3>
                    <p className="text-base font-medium text-gray-700 dark:text-gray-400">
                      Today
                    </p>
                  </div>
                  <div className="flex items-center space-x-3 px-2.5 py-0.5 text-medium text-center">
                    <h3 className="text-medium font-semibold text-green-500 dark:text-green-500">
                      {/* {enrollments?.reduce((item, { status }) => {
                      if (status === "pending") {
                        item += 1;
                      }
                      return item;
                    }, 0)}{" "} */}
                      {activeEnrollmentCount}
                    </h3>
                    <p className="text-base font-medium text-gray-700 dark:text-gray-400">
                      Active{" "}
                    </p>
                  </div>
                  <div className="flex items-center space-x-3 px-2.5 py-0.5 text-medium">
                    <h3 className="text-medium font-semibold text-blue-600 dark:text-blue-600">
                      {/* {enrollments?.reduce((item, { status }) => {
                      if (status === "pending") {
                        item += 1;
                      }
                      return item;
                    }, 0)}{" "} */}
                      {pendingEnrollmentsCount}
                      {/* {registrationCount} */}
                    </h3>
                    <p className="text-base font-medium text-gray-700 dark:text-gray-400">
                      Pending
                    </p>
                  </div>
                </div>
              </div>
              <div id="area-chart"></div>
              <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
                <div className="flex justify-between items-center pt-5">
                  <Link
                    to="/dashboard/enrollments"
                    className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
                  >
                    See all
                    <svg
                      className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
          <div className="flex justify-between">
            <div>
              <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                0
              </h5>
              <p className="text-base font-medium text-gray-500 dark:text-gray-400">
                Payment Requests
              </p>
            </div>
            {/* <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
                12%
                <svg
                  className="w-3 h-3 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13V1m0 0L1 5m4-4 4 4"
                  />
                </svg>
              </div> */}
          </div>
          <div id="area-chart"></div>
          <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
            <div className="flex justify-between items-center pt-5">
              <Link
                to="/dashboard/payment-requests"
                className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
              >
                See all
                <svg
                  className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
          <div className="flex justify-between">
            <div>
              <h5 className="leading-none text-3xl font-bold text-gray-900 dark:text-white pb-2">
                {/* KSh.{" "}
                  {transactions?.reduce((item, { status, amount }) => {
                    if (status === "success") {
                      item += amount;
                    }
                    return item;
                  }, 0)} */}
                0.00
              </h5>
              <p className="text-base font-medium text-gray-500 dark:text-gray-400">
                Total Amount Paid
              </p>
            </div>
            {/* <div className="flex items-center px-2.5 py-0.5 text-base font-semibold text-green-500 dark:text-green-500 text-center">
                12%
                <svg
                  className="w-3 h-3 ms-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13V1m0 0L1 5m4-4 4 4"
                  />
                </svg>
              </div> */}
          </div>
          <div id="area-chart"></div>
          <div className="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
            <div className="flex justify-between items-center pt-5">
              <Link
                to="/dashboard/transactions"
                className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 hover:text-blue-700 dark:hover:text-blue-500  hover:bg-gray-100 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 px-3 py-2"
              >
                See all
                <svg
                  className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="px-8 sm:flex sm:items-start sm:justify-between space-y-2 space-x-3">
        <RegistrationsCard registrationsPerCourse={registrationsPerCourse} />
        <EnrollmentsCard enrollmentsPerCourse={enrollmentsPerCourse} />
      </div>
    </>
  );
};
