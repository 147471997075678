import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

// export const fetchEnrollments = createAsyncThunk(
//   "/enrollments/fetchEnrollments",
//   async (params) => {
//     const { data: { data } } = await Apis.getEnrollments(params);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const fetchEnrollments = createAsyncThunk(
  "/enrollments/fetchEnrollments",
  async ({ page, pageSize, search, createdFrom, createdTo }, { getState }) => {
    try {
      // check stored cache
      const { enrollment } = getState();
      // console.log("state", enrollment);
      const cachedPage =
        enrollment.pages[`${page}-${search}-${createdFrom}-${createdTo}`];
      if (cachedPage) {
        return {
          students: cachedPage,
          total: enrollment.totalShowing,
          page,
          search,
        };
      }
      const {
        data: { data, total },
      } = await Apis.getEnrollments({
        params: { page, pageSize, search, createdFrom, createdTo },
      });

      return { data, total, page, search, createdFrom, createdTo };
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchEnrollment = createAsyncThunk(
  "/enrollments/fetchStudentEnrollment",
  async (params) => {
    const {
      data: { data, msg, success },
    } = await Apis.getStudentEnrollment(params);
    if (success) {
      return { data: data, msg: msg, status: 200 };
    } else {
      return null;
    }
  }
);

export const enrollStudent = createAsyncThunk(
  "/enrollment/enrollStudent",
  async (enrollData) => {
    // if (response.data) {
    //   return response.data;
    // } else {
    //   return [];
    // }

    try {
      // const { status, data } = await Apis.makePayment(paymentInfo);
      const response = await Apis.enroll(enrollData);
      const { data } = response;
      return { ...data, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      return { ...response, status: status  };
    }
  }
);
export const updateEnrollment = createAsyncThunk(
  "/enrollment/updateEnrollment",
  async (updates) => {
    try {
      const response = await Apis.updateEnrollment(updates);
      const { data } = response;
      return { ...data, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      return { ...response, status: status };
    }
  }
);
export const removeEnrollment = createAsyncThunk(
  "/enrollment/removeEnrollment",
  async (params) => {
    try {
      const response = await Apis.deleteEnrollment(params);
      const { data } = response;
      return { ...data, status: 200 };
    } catch (error) {
      const { data: response } = error.response;
      const { status } = error;
      return { ...response, status: status };
    }
  }
);

// export const searchStudent = createAsyncThunk(
//   "/enrollment/searchRegistrationStatus",
//   async (param) => {
//     const { data: response } = await Apis.getRegistration(param);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );
export const fetchCount = createAsyncThunk(
  "/enrollment/fetchCount",
  async () => {
    const {
      data: { count },
    } = await Apis.getEnrollmentCount();
    // console.log(response.data);
    return count;
  }
);

export const verifyEnrollment = createAsyncThunk(
  "enrollment/verifyEnrollment",
  async (verificationData) => {
    try {
      const {
        data: { data, msg, success },
        status,
      } = await Apis.verifyEnrollment(verificationData);
      if (!success) {
        return { data: null, msg: msg, status: status };
      }
      return { data: data, msg: msg, status: status };
    } catch (error) {
      const {
        response: { data },
        status,
      } = error;
      return { data: null, msg: data.msg, status: status };
    }
  }
);

export const pay = createAsyncThunk("enrollment/pay", async (paymentInfo) => {
  try {
    const {
      data: { data, msg },
      status,
    } = await Apis.makePayment(paymentInfo);

    return { data: data, msg: msg, status: status };
  } catch (error) {
    const {
      response: { data },
      status,
    } = error;
    return { data: null, msg: data.msg, status: status };
  }
});

const initialState = {
  enrollments: [],
  status: "idle",
  error: null,
  enrollment: {},
  enrollmentResponse: {},
  studentInfo: {},
  verificationResponse: {},
  paymentResponse: {},
  currentPage: 1,
  pageSize: 100,
  totalActiveCount: 0,
  totalPendingCount: 0,
  todayCount: 0,
  countPerCourse: [],
  totalShowing: 0,
  pages: {},
  searchTerm: "",
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    setEnrollResponse: (state, action) => {
      return { ...state, enrollmentResponse: action.payload };
    },
    setEnrollData: (state, action) => {
      return { ...state, enrollment: action.payload };
    },
    setStudentInfo: (state, action) => {
      return { ...state, studentInfo: action.payload };
    },
    setPayResponse: (state, action) => {
      return { ...state, paymentResponse: action.payload };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
  selectors: {
    selectEnrollStatus: (state) => state.status,
    selectEnrollError: (state) => state.error,
    selectEnrollment: (state) => state.enrollment,
    // selectEnrollments: (state, page) => state.enrollments[page] || [],
    selectEnrollments: (state, page) => state.enrollments,
    selectStudentInfo: (state) => state.studentInfo,
    selectEnrollResp: (state) => state.enrollmentResponse,
    selectVerification: (state) => state.verificationResponse,
    selectPayResponse: (state) => state.paymentResponse,
    selectTotalCount: (state) =>
      state.totalPendingCount + state.totalActiveCount,
    selectTotalActive: (state) => state.totalActiveCount,
    selectTotalPending: (state) => state.totalPendingCount,
    selectCountPerCourse: (state) => state.countPerCourse,
    selectCurrentPage: (state) => state.currentPage,
    selectPageSize: (state) => state.pageSize,
    selectTodayCount: (state) => state.todayCount,
    selectTotalShowing: (state) => state.totalShowing,
    selectSearchTerm: (state) => state.searchTerm,
  },
  extraReducers(builder) {
    builder
      .addCase(enrollStudent.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.enrollmentResponse = {
            enrollment_id: data.enrollment_id,
            student_msisdn: data.student_msisdn,
          };
        }
      })
      .addCase(enrollStudent.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(enrollStudent.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateEnrollment.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.enrollment = data;
          // state.enrollments = state.enrollments.map((enrollment) => {
          //   if (enrollment.id === data.id) {
          //     const updated = { ...enrollment, ...data };
          //     return updated;
          //   } else {
          //     return enrollment;
          //   }
          // });
        }
      })
      .addCase(updateEnrollment.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updateEnrollment.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(verifyEnrollment.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.verificationResponse = data;
      })
      .addCase(verifyEnrollment.pending, (state) => {
        state.status = "pending";
      })
      .addCase(verifyEnrollment.rejected, (state, action) => {
        state.status = "failed";
        const { msg } = action.payload;
        state.error = msg;
      })
      .addCase(pay.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.paymentResponse = {
            payment_request_id: data.payment_request_id,
            student_msisdn: data.student_msisdn,
          };
        }
      })
      .addCase(pay.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(pay.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchEnrollments.fulfilled, (state, action) => {
        const { data, total, page, search } = action.payload;
        state.status = "success";
        state.enrollments = data;
        state.totalShowing = total;
        state.pages[`${page}-${search}`] = data; // cache results
        // if (Array.isArray(data)) {
        //   state.enrollments[page] = data;
        // } else {
        //   state.enrollment = action.payload;
        // }
      })
      .addCase(fetchEnrollments.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchEnrollments.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchEnrollment.fulfilled, (state, action) => {
        state.status = "success";
        state.enrollment = action.payload.data;
      })
      .addCase(fetchEnrollment.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchEnrollment.rejected, (state, action) => {
        state.status = "failed";
      }) // Fetch total count of students
      .addCase(fetchCount.fulfilled, (state, action) => {
        const { active, pending, today, per_course } = action.payload;
        state.totalActiveCount = active;
        state.totalPendingCount = pending;
        state.todayCount = today;
        state.countPerCourse = per_course;
      })
      .addCase(fetchCount.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});
export const {
  setEnrollData,
  setEnrollResponse,
  setStudentInfo,
  setPayResponse,
  setCurrentPage,
  setPageSize,
  setSearchTerm
} = enrollmentSlice.actions;

export const {
  selectEnrollStatus,
  selectEnrollments,
  selectStudentInfo,
  selectEnrollResp,
  selectEnrollError,
  selectVerification,
  selectPayResponse,
  selectEnrollment,
  selectTotalCount,
  selectTodayCount,
  selectTotalActive,
  selectTotalPending,
  selectCurrentPage,
  selectPageSize,
  selectTotalShowing,
  selectSearchTerm,
  selectCountPerCourse,
} = enrollmentSlice.selectors;

export default enrollmentSlice.reducer;
