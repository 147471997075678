import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Alert from "../../components/common/alert";

import {
  selectRegistrationState,
  selectStudentById,
  updateRegistration,
  fetchRegistrations,
  getStudent,
} from "../../features/registrations/registrationSlice";
import { saveItem } from "../../utils/helpers/localStorage";

export const EditStudent = () => {
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(false);
  const [student, setStudent] = useState({});
  const [updates, setUpdates] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [err, setErr] = useState("");
  const [errType, setErrType] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = location.state;
  const studentObject = useSelector((state) => selectStudentById(state, id));
  // const registrationStatus = useSelector(selectRegistrationState);

  const handlePolicyInput = (e) => {
    setPrivacyPolicyStatus(!privacyPolicyStatus);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setStudent({ [name]: value });
    setUpdates({ ...updates, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const { enrollments, ...updateInfo } = student;
      const payload = { ...updates, id: student.id };
      console.log("Update Data", payload);
      const { data, msg, status } = await dispatch(
        updateRegistration(payload)
      ).unwrap();
      if (status !== 200) {
        console.log("status", status);
        setErr(msg);
        setShowAlert(true);
        setErrType("failed");
      } else {
        saveItem("student", data);
        setShowAlert(false);
        setShowLoading(true);
        navigate("/registration-confirmation");
      }
    } catch (error) {
      console.log("Err", error);
      setErr("Oops! That didn't work. Please try again.");
      setShowAlert(true);
    }
  };
  const fetchStudent = useCallback(async () => {
    await dispatch(getStudent({ params: { q: id } }))
      .unwrap()
      .then((results) => {
        setShowLoading(false);
        setStudent(results);
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 6000);
    }
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    }
    if (!studentObject) {
      fetchStudent();
    } else {
      setShowLoading(false);
      setStudent(studentObject);
    }
  }, [fetchStudent, showAlert, showLoading, studentObject]);

  return (
    <>
      {/* <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden"> */}
      <div className="px-4 py-3 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/freewa-8.png"
              alt=""
              className="h-40 w-auto"
            ></img>
          </a>
        </div>
      </div>

      <h1 className="sr-only">IYF Free Academy - Registration</h1>

      {/* <!-- Checkout form --> */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="pb-4 pt-12 lg:flex">
            <a href="/">
              <span className="sr-only">IYF Free Academy</span>
              <img
                src="../assets/png/freewa-8.png"
                alt=""
                className="h-40 w-auto"
              ></img>
            </a>
          </div>
          {showLoading ? (
            <div className="mx-auto max-w-lg">
              <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Update Student
                </h5>
                <div
                  role="status"
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Processing your request ...</span>
                </div>
              </div>
            </div>
          ) : (
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <h1 className="text-2xl font-bold">
                      Season 6 IYF Free Weekend Academy Registration
                    </h1>
                    <p className="mb-2">
                      <b>
                        <u>
                          Please Note the program is on a first come first
                          served basis, Courses that reach a limited number of
                          trainees are closed
                          until&nbsp;the&nbsp;next&nbsp;intake!
                        </u>
                      </b>
                    </p>
                    <p className="mb-2">
                      The IYF Weekend Academy is a 3 Months Training Course
                    </p>
                    <p className="mb-2">
                      We will have an information session (ORIENTATION DAY) ON
                      Saturday, 22nd June 2024 @2:00PM at the IYF HEADQUARTERS,
                      Off Thika road, Behind the Safari Park Hotel, Next to
                      USIU.{" "}
                    </p>
                    <p className="mb-2">
                      For Information Call 0708 333 444 or 0704 333 111 – follow
                      us on our Social Media Platforms; FB, IG, Tiktok & X
                      @IYFKenya.
                    </p>
                  </div>
                </div>
                <div className="col-span-full">
                  {showAlert && (
                    <Alert
                      show={showAlert}
                      content={err}
                      type={errType}
                    ></Alert>
                  )}
                </div>
                {!student && showLoading ? (
                  <div className="mx-auto max-w-lg">
                    <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                      <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                        Loading Student Details
                      </h5>
                      <div
                        role="status"
                        className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading student ...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-full space-y-3">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="full_name"
                        className="block text font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="full_name"
                          id="full_name"
                          autoComplete="Full Name"
                          placeholder="Full Name"
                          onChange={handleInputChange}
                          defaultValue={student.full_name}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        ></input>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="msisdn"
                          className="block text font-medium text-gray-700"
                        >
                          Phone Number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="msisdn"
                            id="msisdn"
                            autoComplete="Phone Number"
                            placeholder="Phone Number"
                            onChange={handleInputChange}
                            defaultValue={student.msisdn}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="email-address"
                          className="block text font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            name="email"
                            id="email-address"
                            autoComplete="Email"
                            placeholder="Email"
                            onChange={handleInputChange}
                            defaultValue={student.email}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="dob"
                          className="block text font-medium text-gray-700"
                        >
                          Date of Birth
                        </label>
                        <div className="mt-1">
                          <input
                            type="date"
                            id="dob"
                            name="dob"
                            autoComplete="email"
                            placeholder="dd/mm/yyyy"
                            onChange={handleInputChange}
                            //defaultValue={student.dob}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="id-number"
                          className="block text font-medium text-gray-700"
                        >
                          ID Number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="id_number"
                            name="id_number"
                            autoComplete="Id Number"
                            placeholder="ID Number"
                            onChange={handleInputChange}
                            defaultValue={student.id_number}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-6">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="guardian_name"
                          className="block text font-medium text-gray-700"
                        >
                          Parent/Guardian Full Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="guardian_name"
                            id="guardian_name"
                            autoComplete="cc-exp"
                            placeholder="Parent/Guardian Full Name"
                            onChange={handleInputChange}
                            defaultValue={student.guardian_name}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full sm:col-span-6">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="guardian_id_no"
                          className="block text font-medium text-gray-700"
                        >
                          Parent/Guardian ID No.
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="guardian_id_no"
                            id="guardian_id_no"
                            autoComplete=""
                            placeholder="Parent/Guardian ID Number"
                            onChange={handleInputChange}
                            defaultValue={student.guardian_id_no}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="parent-phone"
                          className="block text font-medium text-gray-700"
                        >
                          Parent/Guardian Phone Number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="parent-phone"
                            name="guardian_msisdn"
                            autoComplete="email"
                            placeholder="eg. 0700 123 456"
                            onChange={handleInputChange}
                            defaultValue={student.guardian_msisdn}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="current_residence"
                          className="block text font-medium text-gray-700"
                        >
                          Current Residence
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="residence"
                            id="current_residence"
                            placeholder="e.g Githiurai"
                            autoComplete="Current Residence"
                            onChange={handleInputChange}
                            defaultValue={student.residence}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <fieldset>
                          <legend className="block text font-medium text-gray-700">
                            How did you know about weekend academy
                          </legend>
                          <div className="mt-3 space-y-2">
                            <div className="flex items-center gap-x-3">
                              <input
                                id="channel-flier"
                                name="channel"
                                type="radio"
                                value="Flier"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="channel-flier"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Flier
                              </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                              <input
                                id="channel-sm"
                                name="channel"
                                type="radio"
                                value="Social Media"
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                onChange={handleInputChange}
                              ></input>
                              <label
                                htmlFor="channel-sm"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Social Media
                              </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                              <input
                                id="channel-fam"
                                name="channel"
                                type="radio"
                                value="Friend/Family"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="channel-fam"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Friend / Family
                              </label>
                            </div>

                            <div className="flex items-center gap-x-3">
                              <input
                                id="channel-member"
                                name="channel"
                                type="radio"
                                value="GNM Member"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="channel-member"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                GNM Member
                              </label>
                            </div>

                            <div className="flex items-center gap-x-3">
                              <input
                                id="channel-student"
                                name="channel"
                                type="radio"
                                value="Continuing Student"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="channel-student"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Continuing Student
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <fieldset>
                          <legend className="block text font-medium text-gray-700">
                            Are you a new student or current student continuing
                            in a new course?
                          </legend>
                          <div className="mt-3 space-y-2">
                            <div className="flex items-center gap-x-3">
                              <input
                                id="status-new"
                                name="enrollment_status"
                                type="radio"
                                value="new"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="status-new"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                New
                              </label>
                            </div>
                            <div className="flex items-center gap-x-3">
                              <input
                                id="status-contd"
                                name="enrollment_status"
                                type="radio"
                                value="continuing"
                                onChange={handleInputChange}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                              ></input>
                              <label
                                htmlFor="status-contd"
                                className="block text-sm font-medium leading-6 text-gray-700"
                              >
                                Continuing
                              </label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <div className="flex space-x-2">
                          <div className="flex h-5 items-center">
                            <input
                              id="privacy_policy"
                              name="privacy_policy"
                              type="checkbox"
                              onChange={handlePolicyInput}
                              checked={privacyPolicyStatus}
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            ></input>
                          </div>
                          <label
                            htmlFor="privacy_policy"
                            className="text-sm font-medium text-gray-700"
                          >
                            Please read our{" "}
                            <a
                              href="privacy_policy.html"
                              className="text-blue-700"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            . By checking this box, you confirm that you
                            understand how we use your information and agree to
                            the collection of your data for educational
                            purposes.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                  disabled={!privacyPolicyStatus}
                >
                  Update
                </button>
                {/* <button
                  type="submit"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Clear form
                </button> */}
              </div>
              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
            </form>
          )}
        </div>
      </section>
      {/* </main> */}
    </>
  );
};
