import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  resetPassword,
  selectAuthError,
  selectAuthState,
  verifyResetPassword,
} from "../../features/auth/authSlice";
import Alert from "../../components/common/alert";

export const ChangePassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmPassword] = useState("");

  const [alert, setAlert] = useState({
    show: false,
    msg: "",
    type: "",
  });

  const error = useSelector(selectAuthError);
  const status = useSelector(selectAuthState);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(verifyResetPassword(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 6000);
    }
  }, [alert]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (newPassword && confirmNewPassword) {
        await dispatch(
          resetPassword({ token, data: { newPassword, confirmNewPassword } })
        )
          .unwrap()
          .then(({ msg, success }) => {
            if (success) {
              setAlert({
                ...alert,
                show: true,
                msg: `${msg}. Redirecting to login ...`,
              });
              setTimeout(() => {
                navigate("/auth/login");
              }, 4000);
            } else {
              setAlert({
                ...alert,
                show: true,
                msg: `${msg}`,
              });
            }
          });
      } else {
        // console.log("No data supplied");
        setAlert({
          ...alert,
          show: true,
          msg: "Invalid Password. Passwords are required",
        });
      }
    } catch (err) {
      console.log("reset password error", err);
      setAlert({
        ...alert,
        show: true,
        msg: err,
      });
    }
  };

  return (
    <>
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="mx-auto max-w-lg">
            <div className="pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy - Login</span>
                <img
                  src="../assets/png/freewa-8.png"
                  alt=""
                  className="h-40 w-auto"
                ></img>
              </a>
            </div>
          </div>
          {status === "pending" ? (
            <Alert show={true} content="loading ..."></Alert>
          ) : status === "failed" ? (
            <Alert show={true} content={error} type={status}></Alert>
          ) : (
            <div>
              {alert.show && (
                <Alert
                  show={alert.show}
                  content={alert.msg}
                  type={status}
                ></Alert>
              )}
              <form className="mt-6 space-y-2" onSubmit={handleSubmit}>
                <p className="px-1.5 text-base">
                  Enter strong new password below.
                </p>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-full border border-gray-200 rounded-xl shadow-sm">
                    <div className="p-3 bg-white">
                      <label
                        htmlFor="newPassword"
                        className="block text font-medium text-gray-700"
                      >
                        New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          onChange={handleNewPasswordChange}
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="p-3 bg-white">
                      <label
                        htmlFor="confirmNewPassword"
                        className="block text font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          onChange={handleConfirmPasswordChange}
                          required
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
