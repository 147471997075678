import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEnrollment,
  selectVerification,
} from "../../features/enrollment/enrollSlice";
import {
  getStudent,
  selectRegistrationState,
  selectStudentById,
} from "../../features/registrations/registrationSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Alert from "../../components/common/alert";
// import { removeItem } from "../../utils/helpers/localStorage";
import { EnrollCard } from "./register";
import { removeItem } from "../../utils/helpers/localStorage";

export const RegisterSuccess = () => {
  const [verifyStatus, setVerifyStatus] = useState(false);
  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(true);
  // const [showAlert, setShowAlert] = useState(false);
  // const [err, setErr] = useState("");
  // const [verifyPayload, setVerifyPayload] = useState({});
  // const enrollStatus = useSelector(selectEnrollStatus);

  // const studentInfo = useSelector(selectStudentInfo);
  // const verifyInfo = useSelector(selectEnrollResp);
  // const verification = useSelector(selectVerification);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { student_id } = location.state;

  const verification = useSelector(selectVerification);
  const studentObject = useSelector((state) =>
    selectStudentById(state, student_id)
  );
  const registrationStatus = useSelector(selectRegistrationState);
  console.log("status", registrationStatus);
  // console.log("status", registrationStatus);
  // console.log("status", registrationStatus);
  const fetchStudentEnrollment = useCallback(async () => {
    // console.log("ststs", state.student_id)
    try {
      await dispatch(fetchEnrollment({ params: { student_id: student_id } }))
        .unwrap()
        .then(({ data, msg, status }) => {
        //   console.log("results:::", data, msg, status);
          if (status !== 200) {
            navigate(-1);
            setVerifyStatus(false);
          } else {
            const enrollment_info = {
              student_id: data.student_id,
              full_name: data.full_name,
              msisdn: data.msisdn,
              registration_no: data.registration_no,
              course_name: data.class.course,
              class_name: data.class.class,
              sessions: data.sessions,
            };
            setStudent(enrollment_info);
            setVerifyStatus(true);
            setLoading(false);
          }
        });
    } catch (error) {}
  }, [dispatch, navigate, student_id]);

  const fetchStudent = useCallback(async () => {
    // console.log("ststs", state.student_id)
    try {
      await dispatch(getStudent({ params: { q: student_id } }))
        .unwrap()
        .then(({ data, msg, status }) => {
          if (status !== 200) {
            navigate(-1);
            setVerifyStatus(false);
          } else {
            // const enrollment_info = {
            //   student_id: data.student_id,
            //   full_name: data.full_name,
            //   msisdn: data.msisdn,
            //   registration_no: data.registration_no,
            //   course_name: data.class.course,
            //   class_name: data.class.class,
            //   sessions: data.sessions,
            // };
            // console.log("results:::", data, msg, status);

            const student_info = {
              student_id: data.id,
              full_name: data.full_name,
              msisdn: data.msisdn,
              residence: data.residence,
              channel: data.channel,
              id_number: data.id_number || data.guardian_id_no,
              status:
                data.status.charAt(0).toUpperCase() + data.status.slice(1),
              registration_no: data.enrollments[0]?.registration_no,
              course_name: data.enrollments[0]?.class.course,
              class_name: data.enrollments[0]?.class.class,
              sessions: data.sessions || [],
            };
            setStudent(student_info);
            // console.log("stInfo", student_info);
            setVerifyStatus(true);
            setLoading(false);
          }
        });
    } catch (error) {}
  }, [dispatch, navigate, student_id]);

  useEffect(() => {
    // const { status, data, msg } = state;
    // console.log("Pay results>>>>", status, data, msg);
    // if (status !== "success") {
    //   setVerifyStatus(false);
    // } else {
    //   console.log("DataData", data)
    //   setVerifyStatus(true);
    //   setStudent(data);
    //   removeItem("enrollInfo");
    // }
    // if (studentInfo) {
    //   setStudent(studentInfo);
    //   setVerifyStatus(true);
    //   setLoading(false);
    // } else {
    //   if (Object.keys(verification || {}).length === 0) {
    //     fetchStudentEnrollment();
    //   } else {
    //     setStudent(verification);
    //     setVerifyStatus(true);
    //     setLoading(false);
    //   }
    // }
    // removeItem("student");
    if (studentObject) {
      setStudent(studentObject);
      setVerifyStatus(true);
      setLoading(false);
    } else {
      fetchStudent();
    }
    removeItem("student");

    // console.log("Enroll verifyInfo >>", verifyInfo);
    // console.log("Enroll studentInfo >>", studentInfo);
    // if (Object.keys(verifyInfo || {}).length !== 0) {
    //   const { student_msisdn, payment_request_id } = verifyInfo;
    //   const data = {
    //     student_msisdn: student_msisdn,
    //     payment_request_id: payment_request_id,
    //   };
    //   setStudent(studentInfo);
    //   const verify = async () => {
    //     try {
    //       console.log("verifyPayload>>>", data);
    //       await dispatch(verifyEnrollment(data)).unwrap();
    //     } catch (error) {
    //       console.log("error", error);
    //       setShowLoading(false);
    //       setStatus(false);
    //       setErr(
    //         "Something went wrong. Please contact admin to verify your enrollment"
    //       );
    //       setShowAlert(true);
    //     } finally {
    //       console.log("std Info", studentInfo);
    //       setShowLoading(false);
    //       setStatus(true);
    //     }
    //   };
    //   verify();
    // } else {
    //   navigate("/");
    // }
  }, [verification, fetchStudentEnrollment, studentObject, fetchStudent]);
  return (
    <>
      <h1 className="sr-only">IYF Free Academy - Enrollment</h1>
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="pb-5 pt-12 lg:flex lg:justify-center lg:items-center lg:h-45">
            <a href="/">
              <span className="sr-only">IYF Free Academy</span>
              <img
                src="../assets/png/freewa-8.png"
                alt=""
                className="h-45 w-auto"
              />
            </a>
          </div>
          {registrationStatus === "pending" ? (
            <div>
              <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  IYF Free Academy Confirmation
                </h5>
                <div
                  role="status"
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Processing your request ...</span>
                </div>
              </div>
            </div>
          ) : student.enrolled &&
            student.enrollments[0]?.status === "ACTIVE" ? (
            <EnrollCard student={student} />
          ) : (
            <form className="mt-6">
              {registrationStatus === "success" && (
                <div>
                  <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                    <div className="col-span-full">
                      <div className="p-8 bg-white border border-gray-200 rounded-xl shadow-sm text-center">
                        <div className="mb-4 flex items-center justify-center">
                          <svg
                            className="h-20 w-20"
                            width="72"
                            height="73"
                            viewBox="0 0 72 73"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.255859"
                              y="0.899719"
                              width="71.4893"
                              height="71.4893"
                              rx="35.7446"
                              fill="#00B267"
                            />
                            <path
                              d="M51.7795 26.6616L30.5259 47.9152L20.8652 38.2545"
                              stroke="white"
                              strokeWidth="3.86428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="p-4">
                          <h1 className="text-2xl font-bold">
                            Registration Successful
                          </h1>
                        
                          <div className="col-span-full">
                            <div className="p-1 mt-2">
                              <dl className="mt-2 divide-y space-x-2 divide-gray-200 border-t border-gray-200">
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">Name</dt>
                                  <dd className="text-gray-900">
                                    {student.full_name}
                                  </dd>
                                </div>

                                {student.id_number && (
                                  <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">
                                      ID Number/Guardian/Parent ID Number
                                    </dt>
                                    <dd className="text-gray-900">
                                      {student.id_number}
                                    </dd>
                                  </div>
                                )}

                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    Phone Number
                                  </dt>
                                  <dd className="text-gray-900">
                                    {student.msisdn}
                                  </dd>
                                </div>
                                {student.guardian_name && (
                                  <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">
                                      Name of Parent
                                    </dt>
                                    <dd className="text-gray-900">
                                      {student.guardian_name}
                                    </dd>
                                  </div>
                                )}
                                {student.guardian_id_no && (
                                  <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">
                                      ID No. of Parent or Guardian
                                    </dt>
                                    <dd className="text-gray-900">
                                      {student.guardian_id_no}
                                    </dd>
                                  </div>
                                )}

                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    Current Residence
                                  </dt>
                                  <dd className="text-gray-900">
                                    {student.residence}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">Course</dt>
                                  <dd className="text-gray-900">
                                    {student.course_name}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    How did you know about weekend academy?
                                  </dt>
                                  <dd className="text-gray-900">
                                    {student.channel}
                                  </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                  <dt className="text-gray-500">
                                    New or Continuing student?
                                  </dt>
                                  <dd className="text-gray-900">
                                    {student.status}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </div>
                        {/* <p>Your Student Number is</p>
                        <h1 className="text-3xl font-bold">
                          {student.registration_no}
                        </h1> */}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <Link
                      to="/register"
                      className="mt-6 rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                    >
                      Register Another
                    </Link>
                    <Link
                      to="/enroll"
                      state={{ studentId: student_id }}
                      className="mt-6 rounded-md border border-transparent bg-blue-600  p-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                    >
                      Enroll
                    </Link>
                  </div>
                </div>
              )}

              {registrationStatus === "failed" && (
                <div>
                  <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <h1 className="text-2xl font-bold">
                          Oops! Something Went wrong
                        </h1>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <div className="p-8 bg-white border border-gray-200 rounded-xl shadow-sm text-center">
                        <div className="mb-4 flex items-center justify-center">
                          <svg
                            className="h-20 w-20"
                            width="72"
                            height="73"
                            viewBox="0 0 72 73"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.255859"
                              y="0.899719"
                              width="71.4893"
                              height="71.4893"
                              rx="35.7446"
                              fill="#00B267"
                            />
                            <path
                              d="M51.7795 26.6616L30.5259 47.9152L20.8652 38.2545"
                              stroke="white"
                              strokeWidth="3.86428"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <p>
                          We could not complete your enrollment at this moment.
                          Please contact admin for assistance to complete your
                          enrollment.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <Link
                      to="/register"
                      className="mt-6 rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                    >
                      Register Another
                    </Link>
                    <Link
                      to="/"
                      className="mt-6 rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                    >
                      Go Home
                    </Link>
                  </div>
                </div>
              )}

              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
            </form>
          )}
        </div>
      </section>
    </>
  );
};
