import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Apis from "../../utils/api/api";

import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../../features/courses/courseSlice";
import {
  enrollStudent,
  updateEnrollment,
} from "../../features/enrollment/enrollSlice";
import { getStudent } from "../../features/registrations/registrationSlice";
import Alert from "../../components/common/alert";
const subCounties = {
  "Westlands Sub-County": {
    areas: [
      "Kitisuru",
      "Parklands/Highridge",
      "Karura",
      "Kangemi",
      "Mountain View",
    ],
  },
  "Dagoretti North Sub-County": {
    areas: ["Kilimani", "Kawangware", "Gatina", "Kileleshwa", "Kabiro"],
  },
  "Dagoretti South Sub-County": {
    areas: ["Mutu-ini", "Ngando", "Riruta", "Uthiru/Ruthimitu", "Waithaka"],
  },
  "Lang'ata Sub-County": {
    areas: ["Karen", "Nairobi West", "Mugumo-ini", "South C", "Nyayo Highrise"],
  },
  "Kibra Sub-County": {
    areas: [
      "Laini Saba",
      "Lindi",
      "Makina",
      "Woodley/Kenyatta Golf Course",
      "Sarang'ombe",
    ],
  },
  "Roysambu Sub-County": {
    areas: ["Githurai", "Kahawa West", "Zimmerman", "Roysambu", "Kahawa"],
  },
  "Kasarani Sub-County": {
    areas: ["Clay City", "Mwiki", "Kasarani", "Njiru", "Ruai"],
  },
  "Ruaraka Sub-County": {
    areas: [
      "Baba Dogo",
      "Utalii",
      "Mathare North",
      "Lucky Summer",
      "Korogocho",
    ],
  },
  "Embakasi South Sub-County": {
    areas: ["Imara Daima", "Kwa Njenga", "Kwa Reuben", "Pipeline", "Kware"],
  },
  "Embakasi North Sub-County": {
    areas: [
      "Kariobangi North",
      "Dandora Area I",
      "Dandora Area II",
      "Dandora Area III",
      "Dandora Area IV",
    ],
  },
  "Embakasi Central Sub-County": {
    areas: [
      "Kayole North",
      "Kayole Central",
      "Kayole South",
      "Komarock",
      "Matopeni/Spring Valley",
    ],
  },
  "Embakasi East Sub-County": {
    areas: [
      "Upper Savanna",
      "Lower Savanna",
      "Embakasi",
      "Utawala",
      "Mihang'o",
    ],
  },
  "Embakasi West Sub-County": {
    areas: ["Umoja I", "Umoja II", "Mowlem", "Kariobangi South"],
  },
  "Makadara Sub-County": {
    areas: ["Makongeni", "Maringo/Hamza", "Harambee", "Viwandani", "Mbotela"],
  },
  "Kamukunji Sub-County": {
    areas: [
      "Pumwani",
      "Eastleigh North",
      "Eastleigh South",
      "Airbase",
      "California",
    ],
  },
  "Starehe Sub-County": {
    areas: [
      "Nairobi Central",
      "Ngara",
      "Ziwani/Kariokor",
      "Pangani",
      "Landimawe",
      "Nairobi South",
    ],
  },
  "Mathare Sub-County": {
    areas: [
      "Hospital",
      "Mabatini",
      "Huruma",
      "Ngei",
      "Mlango Kubwa",
      "Kiamaiko",
    ],
  },
};

export const GroupedSelect = ({ onChange }) => {
  const [search, setSearch] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Filter areas based on search
  const filteredSubCounties = Object.keys(subCounties).reduce(
    (acc, subCounty) => {
      const filteredAreas = subCounties[subCounty].areas.filter((area) =>
        area.toLowerCase().includes(search.toLowerCase())
      );
      if (filteredAreas.length > 0) {
        acc[subCounty] = filteredAreas;
      }
      return acc;
    },
    {}
  );

  const handleSelect = (area) => {
    setSelectedArea(area);
    setSearch(area); // Set search input to selected area
    setIsDropdownOpen(false);
    onChange(area); // Pass value to parent
  };

  // Handle clicks outside dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setIsDropdownOpen(false);
       }
    };
     if (isDropdownOpen) {
       document.addEventListener("mousedown", handleClickOutside);
     }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownOpen]);

  return (
    <div className="grid grid-cols-12 gap-x-4 gap-y-4">
      <div className="col-span-full">
        <div
          className="p-4  bg-white border border-gray-200 rounded-xl shadow-sm"
          ref={dropdownRef}
        >
          <label
            htmlFor="full_name"
            className="block text font-medium text-gray-700"
          >
            Please select the area closest to where you live
          </label>
          <div className="mt-1">
            <input
              type="text"
              placeholder="Search Area..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setIsDropdownOpen(true)}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
            {isDropdownOpen && (
              <div className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6  max-h-60 overflow-y-auto">
                {Object.keys(filteredSubCounties).length === 0 ? (
                  <div className="p-2 text-gray-500">No areas found</div>
                ) : (
                  Object.entries(filteredSubCounties).map(
                    ([subCounty, areas]) => (
                      <div key={subCounty}>
                        <div className="p-2 bg-gray-100 font-bold text-sm sticky top-0">
                          {subCounty}
                        </div>

                        {areas.map((area) => (
                          <div
                            key={area}
                            className="p-2 cursor-pointer hover:bg-blue-100"
                            onClick={() => handleSelect(area)}
                          >
                            {area}
                          </div>
                        ))}
                      </div>
                    )
                  )
                )}
              </div>
            )}
          </div>
          {/* <div className="max-h-40 overflow-y-auto"></div> */}

          {/* <div className="mt-1">
            <select
              id="select-area"
              name="area"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              value={selectedArea}
              onChange={(e) => {
                setSelectedArea(e.target.value);
                onChange(e.target.value);
              }}
            >
              {filteredAreas.length === 0 ? (
                <option value="">No areas found</option>
              ) : (
                <option value={selectedArea}>
                  {selectedArea || "Select an area"}
                </option>
              )}
              {filteredSubCounties.map((subCounty) => (
                <optgroup key={subCounty} label={subCounty}>
                  {subCounties[subCounty].areas.map((area) => (
                    <option key={area} value={area}>
                      {area}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export const Enroll = () => {
  const [student, setStudent] = useState({});
  const [selectedClass, setSelectedClass] = useState("");
  const [enrollmentData, setEnrollmentData] = useState({});
  const [showClasses, setShowClasses] = useState(true);
  const [showSessions, setShowSessions] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [saturdaySession, setSatSession] = useState("");
  const [sundaySession, setSunSession] = useState("");
  const [chapelSession, setChapelSession] = useState("");
  const [courseItem, setCourseItem] = useState({});
  const [classId, setClassId] = useState(null);
  const [area, setArea] = useState("");
  const [status, setStatus] = useState(false);
  const [enrolled, setEnrolled] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParam, setSearchParamParam] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // const newStudent = useSelector(selectStudent);
  const courseStatus = useSelector(selectCourseStatus);
  // const enrollStatus = useSelector(selectEnrollStatus);
  const courses = useSelector(selectCourses);
  // const classList = useSelector((state) => selectClasses(state));
  const { state } = location;

  const handleCourseInput = (e) => {
    setShowSessions(false);
    // // setSessionsInput([]);
    // setShowClasses(false);
    setSatSession("");
    setSunSession("");
    setSelectedClass("");
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );

    if (course && course.classes.length !== 0) {
      setCourseItem(course);
      setClasses(course.classes);
      setShowClasses(true);
      if (course.classes.length > 1) {
        // setShowClasses(true);
        setShowSessions(false);
      } else {
        const courseClass = course.classes[0];
        setClassId(courseClass.id);
        // console.log("sessions", sessionsInput);
        //  if (courseClass.sessions.length !== 0) {
        //    setSessions(courseClass.sessions);
        //    setShowSessions(true);
        //  } else {
        //    setShowSessions(false);
        //  }
        // console.log("classId", course.classes[0].id);
        // setEnrollmentData({
        //   ...enrollmentData,
        //   class_id: course.classes[0].id,
        // });
      }
      // setEnrollmentData({
      //   ...enrollmentData,
      //   amount:
      //     course.course_price !== "None"
      //       ? parseFloat(course.course_price)
      //       : 1.0,
      // });
    }
  };

  //  const handleSessionInputChange = (e, position) => {
  //    const val = parseInt(e.target.value);
  // console.log("sessions", sessionsInput);
  //    // If the placeholder is selected (empty or invalid value), remove the session at that position
  //    if (!val || isNaN(val) ) {
  //      let updatedSessions = [...sessionsInput];
  //      updatedSessions.splice(position, 1); // Remove the session at the given position (0 or 1)
  //      const arrUpdated = updatedSessions.filter(i => i !== undefined)
  //      console.log("iiiiiii", arrUpdated);
  //      setSessionsInput(arrUpdated);
  //      return;
  //    }

  //    // Handle session addition/removal based on position (index)
  //    if (sessionsInput[position] !== undefined) {
  //      // Replace the existing session at the specific position
  //      let updatedSessions = [...sessionsInput];
  //      updatedSessions[position] = val;
  //      setSessionsInput(updatedSessions);
  //    } else if (sessionsInput.length < 2) {
  //      // Add new session if less than 2 are selected
  //      let updatedSessions = [...sessionsInput];
  //      updatedSessions[position] = val;
  //      setSessionsInput(updatedSessions);
  //    } else {
  //      alert("You can only select up to 2 sessions.");
  //    }
  //  };

  //  const handleSundaySession = (e, position) => {
  //    const val = parseInt(e.target.value);
  //    console.log("sessions", sessionsInput);
  //    if (!val || isNaN(val)) {
  //      // If the value is invalid, remove the session at the given position
  //      let updatedSessions = sessionsInput.filter(
  //        (_, index) => index !== position
  //      );
  //      setSessionsInput(updatedSessions);
  //      return;
  //    }
  //    // Check if the value already exists in the array (but not at the current position)
  //    if (sessionsInput.includes(val) && sessionsInput[position] !== val) {
  //      return; // Simply return if duplicate is found
  //    }

  //    let updatedSessions = [...sessionsInput];

  //    // If there is already a session at the position, replace it
  //    if (updatedSessions[position] !== undefined) {
  //      updatedSessions[position] = val;
  //    }
  //    // Add new session if less than 2 exist
  //    else if (updatedSessions.length < 2) {
  //      updatedSessions[position] = val;
  //    } else {
  //      alert("You can only select up to 2 sessions.");
  //      return;
  //    }

  //    // Update state with the new or replaced session
  //    setSessionsInput(updatedSessions.filter(Boolean));
  //  };
  //  const handleSessionInputChange = (e, position) => {
  //    const val = parseInt(e.target.value);
  //    console.log("sessions", sessionsInput);
  //    if (!val || isNaN(val)) {
  //      // If the value is invalid, remove the session at the given position
  //      let updatedSessions = sessionsInput.filter(
  //        (_, index) => index !== position
  //      );
  //      setSessionsInput(updatedSessions);
  //      return;
  //    }
  //    // Check if the value already exists in the array (but not at the current position)
  //    if (sessionsInput.includes(val) && sessionsInput[position] !== val) {
  //      return; // Simply return if duplicate is found
  //    }

  //    let updatedSessions = [...sessionsInput];

  //    // If there is already a session at the position, replace it
  //    if (updatedSessions[position] !== undefined) {
  //      updatedSessions[position] = val;
  //    }
  //    // Add new session if less than 2 exist
  //    else if (updatedSessions.length < 2) {
  //      updatedSessions[position] = val;
  //    } else {
  //      alert("You can only select up to 2 sessions.");
  //      return;
  //    }

  //    // Update state with the new or replaced session
  //    setSessionsInput(updatedSessions.filter(Boolean));
  //  };
  //  const handleSessionInputChange = (e, position) => {
  //    const val = parseInt(e.target.value);
  //    console.log("sessions", sessionsInput);
  //    if (!val || isNaN(val)) {
  //      // If the value is invalid, remove the session at the given position
  //      let updatedSessions = sessionsInput.filter(
  //        (_, index) => index !== position
  //      );
  //      setSessionsInput(updatedSessions);
  //      return;
  //    }
  //    // Check if the value already exists in the array (but not at the current position)
  //    if (sessionsInput.includes(val) && sessionsInput[position] !== val) {
  //      return; // Simply return if duplicate is found
  //    }

  //    let updatedSessions = [...sessionsInput];

  //    // If there is already a session at the position, replace it
  //    if (updatedSessions[position] !== undefined) {
  //      updatedSessions[position] = val;
  //    }
  //    // Add new session if less than 2 exist
  //    else if (updatedSessions.length < 2) {
  //      updatedSessions[position] = val;
  //    } else {
  //      alert("You can only select up to 2 sessions.");
  //      return;
  //    }

  //    // Update state with the new or replaced session
  //    setSessionsInput(updatedSessions.filter(Boolean));
  //  };

  // export default GroupedSelect;

  const handleClassInputChange = (e) => {
    let val = e.target.value;
    setSelectedClass(val);
    const class_ = classes.find((item) => item.id === parseInt(val));
    if (class_) {
      setClassId(class_.id);
      if (class_.sessions.length !== 0) {
        setSessions(class_.sessions);
        setShowSessions(true);
      } else {
        setShowSessions(false);
      }
      // setSessionChecked(new Array(class_.sessions.length).fill(false));
      setEnrollmentData({
        ...enrollmentData,
        class_id: parseInt(val),
      });
    } else {
      setShowSessions(false);
    }
  };

  const handleAreaChange = (selectedArea) => {
    setArea(selectedArea);
  };

  const handleSearchInputChange = (e) => setSearchParamParam(e.target.value);

  const search = async (param) => {
    try {
      setShowLoading(true);
      // const response = await Apis.getRegistrations(param.replace(" ", ""));
      const response = await Apis.fetchStudent({
        params: { q: param.replace(" ", "") },
      });
      if (response.status === 200) {
        setShowLoading(false);
        const searchResults = response.data.data;
        setStudent(searchResults);
        if (searchResults.enrollments.length !== 0) {
          // setEnrolled(true);
          if (searchResults.sessions.length > 1) {
            setEnrolled(false);
            navigate("/enrollment-successful", {
              state: { enrolled: true, student_id: searchResults.id },
            });
          }
          setEnrollmentStatus(true);
          const course = courses.find(
            (item) => item.id === searchResults.enrollments[0].class.course_id
          );
          setClasses(course.classes);
          setShowClasses(true);
          if (course.classes.length > 1) {
            setShowSessions(false);
          } else {
            // setClasses(course.classes);
            const course_class = course.classes.find(
              (item) => item.id === searchResults.enrollments[0].class.class_id
            );
            setClassId(course_class.id);
            // if (course_class.sessions.length !== 0) {
            //   setSessions(course_class.sessions);
            //   setShowSessions(true);
            // } else {
            //   setShowSessions(false);
            // }
          }
        } else {
          setShowSessions(false);
          setShowClasses(false);
        }
        // setSessionChecked(new Array(class_.sessions.length).fill(false));
      } else {
        setShowError(true);
      }
    } catch (error) {
      setShowLoading(false);
      // console.log("error", error);
      setAlertMsg(error.message);
      setShowError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(
    //   "sessions",
    //   [parseInt(saturdaySession), parseInt(chapelSession)],
    //   classId
    // );
    try {
      // await dispatch(enrollStudent(payload))
      // const sessionsInput = [
      //   parseInt(saturdaySession),
      //   parseInt(sundaySession),
      //   // parseInt(chapelSession),
      // ];
      // if (!isNaN(parseInt(chapelSession))) {
      //   sessionsInput.push(parseInt(chapelSession));
      // }

      //check if enrolled and update enrollment else enroll
      // if (enrollmentStatus) {
      //       payload = {
      //         ...enrollmentData,
      //         student_id: student.id,
      //         // id: student.enrollments[0].id,
      //         sessions: sessionsInput,
      //         class_id: classId,
      //       };
      //   }else{
      //     const payload = {
      //       ...enrollmentData,
      //       student_id: student.id,
      //       // id: student.enrollments[0].id,
      //       sessions: sessionsInput,
      //       class_id: classId,
      //     };
      //   }
      const payload = {
        ...enrollmentData,
        student_id: student.id,
        id: student.enrollments[0]?.id,
        // sessions: sessionsInput,
        class_id: classId,
        area: area,
      };
      // console.log("payload", payload);
      await dispatch(updateEnrollment(payload))
        .unwrap()
        .then((results) => {
          // console.log("Enrollment Resp", results);
          const { data, msg, status } = results;
          setAlertMsg(msg);
          if (status !== 200) {
            // setShowClasses(false);
            // setShowSessions(false);
            setStatus("failed");
            setShowAlert(true);
          } else {
            const enrollment_info = {
              student_id: data.student_id,
              full_name: data.full_name,
              msisdn: data.msisdn,
              registration_no: data.registration_no,
              course_name: data.class.course,
              class_name: data.class.class,
              sessions: data.sessions
            };
            // setStudent(enrollment_info);
            // const enrollResp = {
            //   enrollment_id: data.enrollment_id,
            //   student_msisdn: data.student_msisdn,
            //   class: data.class,
            //   course: data.course,
            // };
            setShowAlert(false);
            setShowLoading(false);
            navigate("/enrollment-successful", {
              state: {
                enrolled: true,
                studentInfo: enrollment_info,
                msg: msg,
              },
            });
            // navigate("/mpesa-express", {
            //   state: { enrollment_id: data.id },
            // });
          }
        });
      // }
      // else {
      //   const payload = {
      //     ...enrollmentData,
      //     student_id: student.id,
      //     sessions: sessionsInput,
      //     // class_id: classId,
      //   };
      // console.log("payload enroll", payload);
      // await dispatch(enrollStudent(payload))
      //   .unwrap()
      //   .then((results) => {
      // console.log("Enrollment Resp", results);
      // const { data, msg, status } = results;
      // setAlertMsg(msg);
      // if (status !== 200) {
      //   setStatus("failed");
      //   setShowAlert(true);
      // } else {
      // const enrollResp = {
      //   enrollment_id: data.enrollment_id,
      //   student_msisdn: data.student_msisdn,
      //   class: data.class,
      //   course: data.course,
      // };
      // setEnrollmentResponse({
      //   ...enrollmentResponse,
      //   enrollment_id: data.enrollment_id,
      //   student_msisdn: data.student_msisdn,
      // });
      // setStudent(data);
      // setStatus("success");
      // setShowAlert(false);
      // setShowLoading(false);
      // navigate("/registration-confirmation", {
      //   state: {
      //     enrollment_id: data.enrollment_id,
      //     student_msisdn: data.student_msisdn,
      //     student_id: data.student_id,
      //   },
      // });
      // navigate("/registration-confirmation", {
      //   state: {
      //     enrollment_id: data.enrollment_id,
      //     student_msisdn: data.student_msisdn,
      //   },
      // });
      // e.target.reset();
      //   }
      // });

      // .then((results) => {
      //   // console.log("Enrollment Resp", results);
      //   const { data, msg, status } = results;
      //   setAlertMsg(msg);
      //   if (status !== 200) {
      //     // setShowClasses(false);
      //     // setShowSessions(false);
      //     setStatus("failed");
      //     setShowAlert(true);
      //   } else {
      //     // const enrollResp = {
      //     //   enrollment_id: data.enrollment_id,
      //     //   student_msisdn: data.student_msisdn,
      //     //   class: data.class,
      //     //   course: data.course,
      //     // };
      //     setShowAlert(false);
      //     setShowLoading(false);
      //     navigate("/mpesa-express", {
      //       state: { enrollment_id: data.enrollment_id },
      //     });
      //   }
      // });
      // }
    } catch (error) {
      console.log(error);
      setAlertMsg(
        "Oops! That didn't work. Please Provide all enrollment information and try again."
      );
      setShowAlert(true);
      setShowClasses(false);
      setShowSessions(false);
    } finally {
      setShowClasses(false);
      setShowSessions(false);
      e.target.reset();
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchParam) {
      search(searchParam);
      setShowLoading(true);
    } else {
      setShowError(true);
    }
  };

  const fetchStudent = useCallback(async () => {
    await dispatch(getStudent({ params: { q: state?.studentId } }))
      .unwrap()
      .then(({ data, msg, status }) => {
        if (data.enrollments.length !== 0) {
          if (data.sessions.length > 1) {
            setEnrolled(false);
            navigate("/enrollment-successful", {
              state: { enrolled: true, student_id: data.id },
            });
          }
          const course = courses.find(
            (item) => item.id === data.enrollments[0].class.course_id
          );
          if (course) {
            setClasses(course.classes);
            const course_class = course.classes.find(
              (item) => item.id === data.enrollments[0].class.class_id
            );
            setClassId(course_class.id);
            if (course_class.sessions.length !== 0) {
              setSessions(course_class.sessions);
              setShowSessions(true);
            } else {
              setShowSessions(false);
            }
          }
          // setEnrolled(true);
          setShowLoading(false);
          setStudent(data);
          setEnrollmentStatus(true);
        } else {
          setShowSessions(false);
          setShowClasses(false);
        }

        // if (data.enrollments.length !== 0) {
        //   setEnrollmentStatus(true)
        // }
      });
  }, [courses, dispatch, navigate, state]);

  useEffect(() => {
    // console.log("courseStatus", courseStatus);
    if (courseStatus === "idle") {
      dispatch(
        fetchCourses({
          page: null,
          pageSize: null,
          search: null,
        })
      );
    }

    if (Object.keys(student || {}).length === 0 && state?.studentId) {
      fetchStudent();
    }
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
      if (showError) {
        setShowError(false);
      }
      if (showLoading) {
        setShowLoading(false);
      }
    }, 5000);
  }, [
    courseStatus,
    dispatch,
    fetchStudent,
    showAlert,
    showError,
    showLoading,
    state,
    student,
  ]);

  return (
    <>
      {/* <div className="px-4 py-6 sm:px-6 lg:hidden"> */}
      {/* <div className="mx-auto flex max-w-lg">
          <a href="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/freewa-8.png"
              alt=""
              className="h-45 w-auto"
            ></img>
          </a>
        </div> */}
      {/* </div> */}
      <h1 className="sr-only">IYF Free Academy - Course Enrollment</h1>
      {/* <!-- Checkout form --> */}
      {/* <div className="pb-5 pt-12 lg:flex lg:justify-center lg:items-center lg:h-45">
        <Link to="/" className="flex justify-center items-center">
          <span className="sr-only">IYF Free Weekend Academy</span>
          <img
            src="../assets/png/freewa-8.png"
            alt=""
            className="h-44 lg:h-44 w-auto"
          ></img>
        </Link>
      </div> */}

      {showLoading && !student ? (
        <div className="mx-auto max-w-lg">
          <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
              Enrollment
            </h5>
            <div
              role="status"
              className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
            >
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Processing your request ...</span>
            </div>
          </div>
        </div>
      ) : (
        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          {Object.keys(student || {}).length !== 0 ? (
            <div className="mx-auto max-w-lg">
              <div className="py-1 lg:flex lg:justify-center lg:items-center lg:h-42">
                <a href="/">
                  <span className="sr-only">
                    IYF Free Academy-Course Enrollment
                  </span>
                  <img
                    src="../assets/png/freewa-8.png"
                    alt=""
                    className="h-42 lg:h-42 w-auto"
                  ></img>
                </a>
              </div>
              <form className="mt-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <h1 className="text-2xl font-bold">
                        {student.full_name},{" "}
                        {student.enrollments?.length !== 0 &&
                          student.enrollments[0]?.class.course}
                      </h1>
                      <h1 className="text-xl font-normal">
                        +{student.msisdn}, {student.registration_no}
                      </h1>
                      {/* <p className="mt-5">
                      You have successfully registered for the Weekend Academy.
                      Register for class sessions
                    </p> */}
                      <p className="mt-3">
                        <b>NB:</b> You can change your course information below
                        incase you made a mistake during the registration.
                      </p>
                    </div>
                  </div>
                  <div className="col-span-full">
                    {showAlert && (
                      <Alert
                        show={showAlert}
                        content={alertMsg}
                        type={status}
                      ></Alert>
                    )}
                  </div>
                  {/* <div className="col-span-full">
                  <p className="block text-xl font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md px-3 py-2">
                    Course Enrollment
                  </p>
                </div> */}
                  {/* {!enrollmentStatus && (
                    <div className="col-span-full">
                      <div className="p-4 bg-red-200 border border-gray-200 rounded-xl shadow-sm">
                        <p>
                          You're Currently not enrolled to any course. Kindly
                          enroll below
                        </p>
                      </div>
                    </div>
                  )} */}

                  {/* {!enrolled && (
                    <div className="col-span-full">
                      <div className="p-4 bg-red-200 border border-gray-200 rounded-xl shadow-sm">
                        <p>
                          You're currently don't have any class sessions. Kindly
                          enroll below to join sessions.
                        </p>
                      </div>
                    </div>
                  )} */}
                  <div className="col-span-full">
                    <GroupedSelect onChange={handleAreaChange} />
                  </div>

                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="select-course"
                        className="block text font-medium text-gray-700"
                      >
                        Course
                      </label>
                      <div className="mt-1">
                        <select
                          id="select-course"
                          name="course"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          defaultValue={student.enrollments[0]?.class.course_id}
                          onChange={handleCourseInput}
                        >
                          <option value="">Select Course</option>
                          {courses.map((course, index) => (
                            <option key={index} value={course.id}>
                              {course.course_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {showClasses && (
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="session"
                          className="block text font-medium text-gray-700"
                        >
                          Class
                        </label>
                        <div className="mt-1">
                          <select
                            id="class_"
                            name="class"
                            placeholder="Select Class"
                            onChange={handleClassInputChange}
                            value={selectedClass}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Class</option>
                            {classes.map((class_, index) => (
                              <option key={index} value={class_.id}>
                                {class_.class_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {showSessions && (
                    <div className="col-span-full">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="session"
                          className="block text font-medium text-gray-700"
                        >
                          Saturday Session
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) => setSatSession(e.target.value)} // For first session input
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Saturday Session</option>
                            {sessions
                              .filter(
                                (item) => item.day.toLowerCase() === "saturday"
                              )
                              .map((session, index) => (
                                <option key={index} value={session.id}>
                                  {session.start_at} - {session.end_at}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="mt-4 p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="session"
                          className="block text font-medium text-gray-700"
                        >
                          Sunday Session
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) => setSunSession(e.target.value)} // For second session input
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">Select Sunday Session</option>
                            {sessions
                              .filter(
                                (item) => item.day.toLowerCase() === "sunday"
                              )
                              .map((session, index) => (
                                <option key={index} value={session.id}>
                                  {session.start_at} - {session.end_at}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="mt-4 p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="session"
                          className="block text font-medium text-gray-700"
                        >
                          Sunday Chapel Session
                        </label>
                        <div className="mt-1">
                          <select
                            onChange={(e) => setChapelSession(e.target.value)} // For second session input
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option value="">
                              Select Sunday Chapel Session
                            </option>
                            {sessions
                              .filter(
                                (item) => item.day.toLowerCase() === "chapel"
                              )
                              .map((session, index) => (
                                <option key={index} value={session.id}>
                                  {session.start_at} - {session.end_at}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div> */}

                      {/* <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <fieldset>
                        <legend className="block text font-medium text-gray-700">
                          Select Class Sessions
                        </legend>

                        {sessions.length !== 0 ? (
                          <div className="mt-3 space-y-2">
                            {sessions.map((session, index) => (
                              <div
                                className="flex items-center gap-x-3"
                                key={index}
                              >
                                <input
                                  id="session"
                                  name="session"
                                  type="checkbox"
                                  onChange={() =>
                                    handleSessionInputChange(index)
                                  }
                                  value={session.id}
                                  checked={setSessionChecked[index]}
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                ></input>
                                <label
                                  htmlFor={"session-" + index}
                                  className="block text-sm font-medium leading-6 text-gray-700"
                                >
                                  {session.day}, Time: {session.start_at} -
                                  {session.end_at}
                                </label>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="mt-3 space-y-2">
                            <div className="flex items-center gap-x-3">
                              <p>------ Class has no sessions yet ------</p>
                            </div>
                          </div>
                        )}
                      </fieldset>
                    </div> */}
                    {/* </div> */}
                  {/* )} */}
                </div>
                <div className="flex justify-between">
                  <button
                    disabled={
                      // saturdaySession === "" ||
                      // sundaySession === "" ||
                      // chapelSession === "" ||
                      area === "" ||
                      classId === null
                    }
                    type="submit"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                  >
                    Enroll
                  </button>
                  <Link
                    to="/"
                    className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          ) : (
            <div className="mx-auto max-w-lg">
              <div className="py-1 lg:flex lg:justify-center lg:items-center lg:h-42">
                <a href="/">
                  <span className="sr-only">
                    IYF Free Academy-Course Enrollment
                  </span>
                  <img
                    src="../assets/png/freewa-8.png"
                    alt=""
                    className="h-42 lg:h-42 w-auto"
                  ></img>
                </a>
              </div>
              <form className="mt-6" onSubmit={handleSearchSubmit}>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <h1 className="text-2xl font-bold">Enroll</h1>
                      <p>
                        Enter the Phone number you used to register to search
                        for your info
                      </p>
                    </div>
                  </div>

                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="phone-email-id"
                        className="block text font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="fullname"
                          id="phone-email-id"
                          placeholder="e.g. 0712345678"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          onChange={handleSearchInputChange}
                        ></input>

                        {/* <!-- Error message --> */}
                        {showError && (
                          <p
                            id="error-message"
                            className="mt-2 text-sm font-medium text-red-600"
                          >
                            Record not found. Please check your input or{" "}
                            <Link className="text-blue-600" to="/register">
                              register here
                            </Link>
                            .
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between">
                  <button
                    type="submit"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Search
                  </button>
                  <Link
                    to="/"
                    className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          )}
          <div className="mx-auto max-w-lg">
            <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
              <svg
                className="mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                  clipRule="evenodd"
                />
              </svg>
              We adhere to the highest standards of data security in all
              processing and storage of your information.
            </p>
          </div>
        </section>
      )}
    </>
  );
};
