import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Alert from "../../components/common/alert";

import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../../features/courses/courseSlice";

import { registerStudent } from "../../features/registrations/registrationSlice";
import { saveItem } from "../../utils/helpers/localStorage";
import {
  enrollStudent,
  fetchEnrollment,
} from "../../features/enrollment/enrollSlice";

// const courseList = [
//   {
//     course_code: "GD001",
//     course_details: "graphic design course",
//     course_name: "Graphics",
//     course_price: null,
//     id: 1,
//   },
//   {
//     course_code: "WD001",
//     course_details: "Web design course",
//     course_name: "Web Design",
//     course_price: null,
//     id: 3,
//   },
//   {
//     course_code: "GD002",
//     course_details: "design course",
//     course_name: "GraphicsDesign",
//     course_price: null,
//     id: 4,
//   },
// ];

export const EnrollCard = ({ student }) => {
  // console.log("Reg student :::", student);
  return (
    <>
      <div className="mx-auto max-w-lg">
        <div className="grid grid-cols-12 gap-x-4 gap-y-6">
          <div className="col-span-full">
            <div className="p-4 text-center space-y-2 bg-white border border-gray-200 rounded-xl shadow-sm">
              {/* {student.enrollment_status === "enrolled" && ( */}
              <div className="mb-4 flex items-center justify-center">
                <svg
                  className="h-10 w-10"
                  width="72"
                  height="73"
                  viewBox="0 0 72 73"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.255859"
                    y="0.899719"
                    width="71.4893"
                    height="71.4893"
                    rx="35.7446"
                    fill="#00B267"
                  />
                  <path
                    d="M51.7795 26.6616L30.5259 47.9152L20.8652 38.2545"
                    stroke="white"
                    strokeWidth="3.86428"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* )} */}
              <h1 className="text-2xl font-bold">{student.full_name}</h1>
              <h1 className="text-xl font-normal">+{student.msisdn}</h1>
              <h1 className="text-xl font-normal">{student.registration_no}</h1>
            </div>
          </div>
          <div className="col-span-full">
            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
              <dl className="mt-2 divide-y space-x-2 divide-gray-200 ">
                <div className="flex justify-center py-3 text-sm font-medium">
                  <dt className="text-gray-900 text-xl">
                    Your Enrollment Info
                  </dt>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Status</dt>
                  <dd className="text-green-600 text-bold">Active</dd>
                  {/* {student.enrollment_status === "pending" ? (
                    <dd className="text-red-700 text-bold">Pending Payment</dd>
                  ) : (
                    <dd className="text-green-600 text-bold">Active</dd>
                  )} */}
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Course</dt>
                  <dd className="text-gray-900">{student.course_name}</dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Class</dt>
                  <dd className="text-gray-900">
                    {/* {student.enrollments[0]?.class_name} */}
                    {student.class_name}
                  </dd>
                </div>
                {student.sessions !== 0 &&
                  student.sessions?.map((session, idx) => (
                    <div
                      key={idx}
                      className="flex justify-between py-3 text-sm font-medium"
                    >
                      <dt className="text-gray-500">{session.day} Session</dt>
                      <dd className="text-gray-900">
                        {session.from} - {session.to}
                      </dd>
                    </div>
                  ))}
              </dl>
            </div>
          </div>

          {/* {student.enrollment_status !== "enrolled" && (
            <div className="col-span-full">
              <Link
                to="/mpesa-express"
                state={{ enrollment_id: student.enrollments[0]?.id }}
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              >
                Pay Now
              </Link>
            </div>
          )} */}
        </div>
        <div className="flex justify-between">
          <Link
            to="/"
            className="flex justify-between space-x-2 mt-6 rounded-md border border-transparent bg-blue-600  p-1 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 px-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
            <span>
              Back
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export const Register = () => {
  const [privacyPolicyStatus, setPrivacyPolicyStatus] = useState(false);
  const [student, setStudent] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [err, setErr] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [age, setAge] = useState(0);
  const [showId, setShowId] = useState(true);
  const [dobDay, setDobDay] = useState(0);
  const [dobMonth, setDobMonth] = useState(0);
  const [dobYear, setDobYear] = useState(0);
  const [enrollmentData, setEnrollmentData] = useState({});
  const [classes, setClasses] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [showClasses, setShowClasses] = useState(false);
  const [classItem, setClassItem] = useState();
  const [status, setStatus] = useState("");
  const [alertMsg, setAlertMsg] = useState(false);
  // const [verificationInfo, setVerificationInfo] = useState({});
  const [registerBtnState, setRegisterBtnState] = useState(true);
  const [enrollmentStatus, setEnrollmentStatus] = useState(false);
  // const [maxDate, setMaxDate] = useState(31);

  const months = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const courseStatus = useSelector(selectCourseStatus);
  // const enrollStatus = useSelector(selectEnrollStatus);
  const courses = useSelector(selectCourses);
  // const registrationStatus = useSelector(selectRegistrationState);
  // const regErrorMsg = useSelector(selectErrorMsg);
  // const added = useSelector(selectStudent);

  const handleCourseInput = (e) => {
    // setShowSessions(false);
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );

    if (course && course.classes.length !== 0) {
      setCourseItem(course);
      setClasses(course.classes);
      if (course.classes.length > 1) {
        setShowClasses(true);
      } else {
        setShowClasses(false);
        setEnrollmentData({
          ...enrollmentData,
          class_id: course.classes[0].id,
          amount: 1,
        });
      }
      // setEnrollmentData({
      //   ...enrollmentData,
      //   amount:
      //     course.course_price !== "None"
      //       ? parseFloat(course.course_price)
      //       : 1.0,
      // });
    }
  };

  const handleClassInputChange = (e) => {
    const class_ = courseItem.classes.find(
      (item) => item.id === parseInt(e.target.value)
    );
    if (class_) {
      setClassItem(class_);
      // setSessions(class_.sessions);
      // setSessionChecked(new Array(class_.sessions.length).fill(false));
      // setShowSessions(true);
      setEnrollmentData({
        ...enrollmentData,
        class_id: parseInt(e.target.value),
      });
    } else {
      // setShowSessions(false);
    }
  };
  // Function to calculate age
  const calculateAge = useCallback(() => {
    if (!dobYear || !dobMonth || !dobDay) return;
    const today = new Date();
    const birthDate = new Date(`${dobYear}-${dobMonth}-${dobDay}`);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() + 1 - birthDate.getMonth(); // Adjust month index
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setAge(age);
    setShowId(age > 18);
  }, [dobDay, dobMonth, dobYear]);

  // const register = async (data) => {
  //   try {
  //     const apiResponse = await Apis.registerStudent(data);
  //     console.log("apiResponse>>> ", apiResponse);
  //     const { data: response } = apiResponse;
  //     //     // console.log("Response", response);
  //     // if (registrationStatus === "failed") {
  //     //   // setErr(response.msg);
  //     //   setShowAlert(true);
  //     //   setStudent({});
  //     // } else {
  //     // }
  //     await dispatch(registerStudent(data));

  //     if(regState === "success"){

  //     }
  //   if (apiResponse.status !== 201) {
  //     setErr(response.msg);
  //     setShowAlert(true);
  //     // console.log("error >>>", response.data.toString());
  //     setStudent({});
  //   } else {
  //     setErr(response.data.msg);
  //     setShowAlert(true);
  //     dispatch(setNewStudent(response.data));
  //     setAddedRecord(response.data);
  //   }
  // } catch (error) {
  //   console.log("error >>>", error);
  //   const { data: response } = error.response;
  //   // setRegState("failed");
  //   setErr(response.msg);
  //   setShowAlert(true);
  //   // setStudent({});
  // }
  // };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setStudent((student) => ({ ...student, [name]: value }));
  }, []);

  const handlePolicyInput = (e) => {
    setRegisterBtnState(!registerBtnState);
    setPrivacyPolicyStatus(!privacyPolicyStatus);
  };

  // const handleCompleteEnrollment = async (e) => {
  //   try {
  //     // console.log("VerificationInfo::", verificationInfo);
  //     if (Object.keys(enrollmentResponse || {}).length !== 0) {
  //       await dispatch(verifyEnrollment(enrollmentResponse))
  //         .unwrap()
  //         .then((results) => {
  //           // console.log("Pay results", results);
  //           const { data, msg, status } = results;
  //           if (status !== 200) {
  //             navigate("/enrollment-successful", {
  //               state: { status: "failed", msg: msg },
  //             });
  //           } else {
  //             navigate("/enrollment-successful", {
  //               state: { status: "success", data: data, msg: msg },
  //             });
  //           }
  //         });
  //     }
  //   } catch (error) {}254808088533
  // };

  const fetchStudentEnrollment = useCallback(
    async (student_id) => {
      // console.log("ststs", state.student_id)
      try {
        await dispatch(fetchEnrollment({ params: { student_id: student_id } }))
          .unwrap()
          .then(({ data, msg, status }) => {
            console.log("Enrollment results:::", data, msg, status);
            if (status !== 200) {
              setEnrollmentStatus(false);
              setStatus("failed");
              setErr(msg);
              setShowAlert(true);
              setRegisterBtnState(false);
            } else {
              if (data.sessions.length === 0) {
                navigate("/enroll", {
                  state: {
                    id: data.student_id,
                  },
                });
              } else {
                const enrollment_info = {
                  student_id: data.student_id,
                  full_name: data.full_name,
                  msisdn: data.msisdn,
                  registration_no: data.registration_no,
                  course_name: data.class.course,
                  class_name: data.class.class,
                  sessions: data.sessions,
                };
                setStudent(enrollment_info);
                setEnrollmentStatus(true);
                // setShowLoading(false);
              }
            }
          });
      } catch (error) {
        setStatus("failed");
        setErr(error.response);
        setShowAlert(true);
        setRegisterBtnState(false);
      }
    },
    [dispatch, navigate]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRegisterBtnState(true);
    if (dobDay === 0 || dobDay > 31) {
      setStatus("failed");
      setErr("Invalid Date of Birth");
      setShowAlert(true);
      setRegisterBtnState(false);
    } else {
      // console.log(dobDay.toString(), dobMonth.toString(), dobYear.toString());
      const Dt = [
        dobDay.toString(),
        dobMonth.toString(),
        dobYear.toString(),
      ].join("-");
      const payload = { ...student, dob: Dt };
      // try {
      // console.log("studentRegData", payload);
      await dispatch(registerStudent(payload))
        .unwrap()
        .then(async (results) => {
          const { data, msg, status } = results;
          if (status !== 201) {
            setErr(msg);
            setStatus("failed");
            setShowAlert(true);
            setRegisterBtnState(false);
          } else {
            // console.log("reg Data", data);
            //   setStudent(data);
            saveItem("student", data);
            // setShowAlert(false);
            // setShowLoading(true);
            // console.log("enroll sat", data.enrollment_status);
            // if (data.exists) {
            //   setErr(msg);
            //   setShowAlert(true);
            // }
            if (data.exists) {
              setErr(msg);
              setStatus("failed");
              setShowAlert(true);
              setRegisterBtnState(false);
            } else {
              if (data.enrollment_status === "not_enrolled") {
                const enrollInfo = {
                  ...enrollmentData,
                  student_id: data.id,
                };
                // console.log("enr info", enrollInfo)
                await dispatch(enrollStudent(enrollInfo))
                  .unwrap()
                  .then((results) => {
                    // console.log("Enrollment Resp", results);
                    const { data, msg, status } = results;
                    setAlertMsg(msg);
                    setErr(msg);
                    if (status !== 200) {
                      setShowClasses(false);
                      // setShowSessions(false);
                      setStatus("failed");
                      setShowAlert(true);
                      // navigate("/mpesa-express", {state: {enrollment_id: data.enrollment_id}});
                      // navigate("/enrollment-successful", {
                      //   state: { status: "failed", msg: msg },
                      setRegisterBtnState(false);
                      // });
                    } else {
                      // const enrollResp = {
                      //   enrollment_id: data.enrollment_id,
                      //   student_msisdn: data.student_msisdn,
                      //   class: data.class,
                      //   course: data.course,
                      // };
                      // setEnrollmentResponse({
                      //   ...enrollmentResponse,
                      //   enrollment_id: data.enrollment_id,
                      //   student_msisdn: data.student_msisdn,
                      // });
                      setStatus("success");
                      setShowAlert(false);
                      setShowLoading(false);

                      // navigate("/enrollment-successful", {
                      //   state: { status: "success", data: data, msg: msg },
                      // });
                      setShowAlert(false);
                      setShowLoading(true);

                      navigate("/registration-confirmation", {
                        state: {
                          enrollment_id: data.enrollment_id,
                          student_msisdn: data.student_msisdn,
                          student_id: data.student_id,
                        },
                      });
                      // navigate("/registration-confirmation", {
                      //   state: {
                      //     enrollment_id: data.enrollment_id,
                      //     student_msisdn: data.student_msisdn,
                      //   },
                      // });
                      // e.target.reset();
                    }
                  });
              } else {
                fetchStudentEnrollment(data.id);
                // setErr(msg);
                // setStatus("failed");
                // setShowAlert(true);
                // setRegisterBtnState(false);
              }
            }

            // }?

            // saveItem("student", data);
            // setShowAlert(false);
            // setShowLoading(true);
            // navigate("/registration-confirmation");
          }
        });
      // dispatch(getNewStudent());
      // dispatch(getStatus());
      // } catch (error) {
      //   setErr("Oops! That didn't work. Please try again.");
      //   setShowAlert(true);
      // }
    }
  };

  useEffect(() => {
    if (courseStatus === "idle") {
      dispatch(
        fetchCourses({
          page: null,
          pageSize: null,
          search: null,
        })
      );
    }
    // if (showAlert) {
    //   setTimeout(() => {
    //     setShowAlert(false);
    //   }, 6000);
    // }
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    }
    // const calculateAge = () => {
    //   if (!dobYear || !dobMonth || !dobDay) return;
    //   const today = new Date();
    //   const birthDate = new Date(`${dobYear}-${dobMonth}-${dobDay}`);
    //   let age = today.getFullYear() - birthDate.getFullYear();
    //   const monthDiff = today.getMonth() + 1 - birthDate.getMonth(); // Adjust month index
    //   if (
    //     monthDiff < 0 ||
    //     (monthDiff === 0 && today.getDate() < birthDate.getDate())
    //   ) {
    //     age--;
    //   }
    //   setAge(age);
    //   setShowId(age > 18);
    // };
    calculateAge();
  }, [
    showAlert,
    showLoading,
    dispatch,
    courses.length,
    dobDay,
    dobMonth,
    dobYear,
    calculateAge,
    courseStatus,
  ]);
  // Calculate max days based on month and leap year logic
  const maxDay =
    dobMonth === "2"
      ? dobYear % 4 === 0 && (dobYear % 100 !== 0 || dobYear % 400 === 0)
        ? 29
        : 28
      : ["4", "6", "9", "11"].includes(dobMonth)
      ? 30
      : 31;

  return (
    <>
      {/* <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden"> */}
      {/* <div className="px-4 py-6 sm:px-6 lg:hidden">
        <div className="mx-auto flex max-w-lg">
          <Link to="/">
            <span className="sr-only">IYF Free Weekend Academy</span>
            <img
              src="../assets/png/freewa-8.png"
              alt=""
              className="h-20 w-auto"
            ></img>
          </Link>
        </div>
      </div> */}

      <h1 className="sr-only">IYF Free Academy - Registration</h1>

      {/* <!-- Checkout form --> */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="py-1 lg:flex">
            <a href="/">
              <span className="sr-only">IYF Free Weekend Academy</span>
              <img
                src="../assets/png/freewa-8.png"
                alt=""
                className="h-40 lg:h-16 w-auto"
              ></img>
            </a>
          </div>
          {showLoading ? (
            <div className="mx-auto max-w-lg">
              <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Registration
                </h5>
                <div
                  role="status"
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Processing your request ...</span>
                </div>
              </div>
            </div>
          ) : enrollmentStatus && student ? (
            <EnrollCard student={student} />
          ) : (
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <h1 className="text-2xl font-bold">
                      Welcome to Season 7 of IYF Free Weekend Academy
                    </h1>
                    <p className="mb-2">
                      <i>
                        <b>Please register yourself once.</b>
                      </i>{" "}
                    </p>

                    <p className="mb-2">
                      <b>
                        <u>
                          Please Note the program is on a first come first
                          served basis, Courses that reach a limited number of
                          trainees are closed until the next intake! The IYF
                          Weekend Academy is a 3 Months Training Course. Season
                          7 beginning 12th Oct 2024.
                        </u>
                      </b>
                    </p>
                    <p className="mb-2">
                      The IYF Weekend Academy is a 3 Months Training Course
                    </p>
                    <p className="mb-2">
                      We will have an information session (ORIENTATION DAY) ON
                      Saturday, 12th October 2024 @9:00am and 2:00PM at the IYF
                      HEADQUARTERS, Off Thika road, Behind the Safari Park
                      Hotel, Next to USIU.
                    </p>
                    <p className="mb-2">
                      For Information Call 0708 333 444 or 0704 333 111 – follow
                      us on our Social Media Platforms; FB, IG, Tiktok & X
                      @IYFKenya.
                    </p>
                  </div>
                  <p className="mt-3 text-normal p-1 leading-6 text-rose-900 rounded-xl">
                    Season 7 registration is closed.
                  </p>
                  {/* <div className="mt-3 text-normal p-3 leading-6 text-center text-white bg-emerald-600 rounded-xl">
                    <p className="py-1.5 font-semibold leading-6 text-white">
                      Register for New Season 8{" "}
                    </p>
                    <span>
                      <a
                        href="https://www.freeacademy.iyfkenya.org"
                        className="bg-gray-600 py-1.5 px-5 text-sm text-white rounded-md hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Here
                      </a>
                    </span>
                  </div> */}
                  <p className="mt-3 text-sm p-5 leading-6 text-white bg-blue-600 rounded-xl">
                    However, If you already registered for season 7 but have not
                    enrolled, proceed to enroll for a course of your choice.{" "}
                    <Link
                      to="/enroll"
                      className="rounded-md border border-emerald-600 bg-orange-400 p-1 text-sm text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      // style={{ cursor: "default" }}
                      // className="mt-6 rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                    >
                      Here
                    </Link>
                  </p>
                </div>
                {/* <div className="col-span-full">
                  {showAlert && (
                    <Alert show={showAlert} content={err} type={status}></Alert>
                  )}
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="full_name"
                      className="block text font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="full_name"
                        id="full_name"
                        autoComplete="Full Name"
                        placeholder="Full Name"
                        onChange={(e) => handleInputChange(e)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        required
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="msisdn"
                      className="block text font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1">
                      <input
                        // type="phone"
                        name="msisdn"
                        id="msisdn"
                        autoComplete="Phone Number"
                        placeholder="Phone Number"
                        pattern="((\+|00)?[1-9]{2}|0)[1-9]([0-9]){8}"
                        onChange={handleInputChange}
                        // onInput={(e) =>
                        //   e.target.setCustomValidity(
                        //     "Phone No. must be 10 digits in the format 0712345678"
                        //   )
                        // }
                        // onInvalid={(e) =>
                        //   e.target.setCustomValidity(
                        //     "Phone No. must be 10 digits in the format 0712345678"
                        //   )
                        // }
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        required
                      ></input>
                    </div>
                  </div>
                </div>


                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="dob"
                      className="block text font-medium text-gray-700"
                    >
                      Date of Birth
                    </label>
                    <div className="grid grid-cols-3 gap-4 mt-2">
                      <div>
                        <select
                          id="dob-month"
                          name="dob-month"
                          defaultValue="Month"
                          // className="block w-full rounded-md border-0 pl-3 py-1 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          className="block border  text-gray-900 text-sm rounded-lg w-full  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          onChange={(e) => setDobMonth(e.target.value)}
                        >
                          <option value="">Month</option>
                          {months.map((month, index) => (
                            <option key={index} value={index + 1}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <input
                          type="number"
                          id="dob"
                          name="dob"
                          placeholder="Day"
                          max={maxDay}
                          min={1}
                          // value={dobDay}
                          onChange={(e) => setDobDay(e.target.value)}
                          className="block border text-gray-900 text-sm rounded-lg w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                      <div>
                        <input
                          type="number"
                          id="dob-year"
                          name="dob-year"
                          placeholder="Year"
                          max={2008} // Assuming no one under 16 can register (year > 2008)
                          min={1900}
                          // value={dobYear}
                          onChange={(e) => setDobYear(e.target.value)}
                          className="block border text-gray-900 text-sm rounded-lg w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {showId ? (
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="id-number"
                        className="block text font-medium text-gray-700"
                      >
                        ID Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="id_number"
                          name="id_number"
                          placeholder="ID Number"
                          pattern="[A-Za-z0-9]{4,15}"
                          value={student?.id_number}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-full">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="guardian_name"
                          className="block text font-medium text-gray-700"
                        >
                          Name of Parent
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="guardian_name"
                            id="guardian_name"
                            placeholder="Parent Full Name"
                            value={student?.guardian_name}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            required
                          />
                        </div>
                      </div>
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="guardian_id_no"
                          className="block text font-medium text-gray-700"
                        >
                          Parent or Guardian ID No.
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="guardian_id_no"
                            id="guardian_id_no"
                            placeholder="Parent Guardian ID Number"
                            pattern="[0-9]{8}"
                            value={student?.guardian_id_no}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full mt-2">
                      <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                        <label
                          htmlFor="parent-phone"
                          className="block text font-medium text-gray-700"
                        >
                          Parent/Guardian Phone Number
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="parent-phone"
                            name="guardian_msisdn"
                            placeholder="eg. 0700 123 456"
                            pattern="[A-Za-z0-9]{4,15}"
                            value={student?.guardian_msisdn}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="current_residence"
                      className="block text font-medium text-gray-700"
                    >
                      Current Residence
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="residence"
                        id="current_residence"
                        placeholder="e.g Githiurai"
                        autoComplete="Current Residence"
                        onChange={handleInputChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        required
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col-span-full">
                  <p className="block text-xl border border-gray-300 font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md p-2">
                    Course Enrollment
                  </p>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <label
                      htmlFor="select-course"
                      className="block text font-medium text-gray-700"
                    >
                      Select Course
                    </label>
                    <div className="mt-1">
                      <select
                        id="select-course"
                        name="course"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        defaultValue="Select Course"
                        onChange={handleCourseInput}
                      >
                        <option value="">Select Course</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.id}>
                            {course.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                {showClasses && (
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <label
                        htmlFor="session"
                        className="block text font-medium text-gray-700"
                      >
                        Select Class
                      </label>
                      <div className="mt-1">
                        <select
                          id="class_"
                          name="class"
                          placeholder="Select Class"
                          onChange={handleClassInputChange}
                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Select Class</option>
                          {classes.map((class_, index) => (
                            <option key={index} value={class_.id}>
                              {class_.class_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        How did you know about weekend academy
                      </legend>
                      <div className="mt-3 space-y-2">
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-flier"
                            name="channel"
                            type="radio"
                            value="Flier"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="channel-flier"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Flier
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-sm"
                            name="channel"
                            type="radio"
                            value="Social Media"
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            onChange={handleInputChange}
                            required
                          ></input>
                          <label
                            htmlFor="channel-sm"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Social Media
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-fam"
                            name="channel"
                            type="radio"
                            value="Friend/Family"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="channel-fam"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Friend / Family
                          </label>
                        </div>

                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-member"
                            name="channel"
                            type="radio"
                            value="GNM Member"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="channel-member"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            GNM Member
                          </label>
                        </div>

                        <div className="flex items-center gap-x-3">
                          <input
                            id="channel-student"
                            name="channel"
                            type="radio"
                            value="Continuing Student"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="channel-student"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Continuing Student
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <fieldset>
                      <legend className="block text font-medium text-gray-700">
                        Are you a new student or current student continuing in a
                        new course?
                      </legend>
                      <div className="mt-3 space-y-2">
                        <div className="flex items-center gap-x-3">
                          <input
                            id="status-new"
                            name="enrollment_status"
                            type="radio"
                            value="new"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="status-new"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            New
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            id="status-contd"
                            name="enrollment_status"
                            type="radio"
                            value="continuing"
                            onChange={handleInputChange}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            required
                          ></input>
                          <label
                            htmlFor="status-contd"
                            className="block text-sm font-medium leading-6 text-gray-700"
                          >
                            Continuing
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className="col-span-full">
                  <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                    <div className="flex space-x-2">
                      <div className="flex h-5 items-center">
                        <input
                          id="privacy_policy"
                          name="privacy_policy"
                          type="checkbox"
                          onChange={handlePolicyInput}
                          checked={privacyPolicyStatus}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          required
                        ></input>
                      </div>
                      <label
                        htmlFor="privacy_policy"
                        className="text-sm font-medium text-gray-700"
                      >
                        Please read our{" "}
                       <Link
                          to="/privacy-policy"
                          className="text-blue-700"
                          target="_blank"
                        >
                          Privacy Policy
                        </Link>
                        . By checking this box, you confirm that you understand
                        how we use your information and agree to the collection
                        of your data for educational purposes.
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>

              {/* <div className="flex justify-between">
                <button
                  type="submit"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                  // disabled={registerBtnState}
                  disabled={true}
                >
                  Register
                </button> */}
              {/* <button
                  type="submit"
                  className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                >
                  Clear form
                </button> */}
              {/* </div> */}
              <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                <svg
                  className="mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                We adhere to the highest standards of data security in all
                processing and storage of your information.
              </p>
              {/* <div className="justify-between gap-y-4">
                <p className="mb-6 mt-6 flex justify-left text-sm font-medium">
                  Already Registered ?
                </p>
                <Link
                  to="/enroll"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Enroll Here
                </Link>
              </div> */}
            </form>
          )}
        </div>
      </section>
      {/* </main> */}
    </>
  );
};
